import React, { useEffect, useState } from "react";
import ReactCardFlip from "react-card-flip";
import AOS from "aos";
import "aos/dist/aos.css";
import axios from "axios";
import "@assets/css/Contact.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { FiSend } from "react-icons/fi";

const Contact = () => {
    const [isFlipped, setIsFlipped] = useState(false);
    const [isFlipped1, setIsFlipped1] = useState(false);
    const handleClick_first = () => {
        setIsFlipped1(true);
    };
    setTimeout(() => {
        handleClick_first();
    }, 5000);
    const handleClick = () => {
        setIsFlipped(!isFlipped);
    };
    const handleClick1 = () => {
        setIsFlipped(false);
    };

    useEffect(() => {
        AOS.init();
    }, []);
    const { executeRecaptcha } = useGoogleReCaptcha();
    const fail = () => toast("Oops... Something went wrong.");
    const [loading, setLoading] = useState(false);
    const [formvalue, setFormvalue] = useState({
        fullname: "",
        phone: "",
        email: "",
        message: "",
    });

    const handleInput = (event) => {
        const { name, value } = event.target;

        let updatedValue = value;

        // Validation for fullname (allowing only alphabets and spaces)
        if (name === "fullname") {
            updatedValue = value.replace(/[^a-zA-Z\s]/g, "");
        }

        if (name === "phone") {
            updatedValue = value.replace(/[^\d]/g, "");

            if (updatedValue.length > 0) {
                updatedValue = updatedValue.replace(/(\d{3})(\d{3})(\d{2})(\d{2})/, "$1-$2 $3 $4");
            }

            if (updatedValue.length > 13) {
                updatedValue = updatedValue.slice(0, 13);
            }
        }

        setFormvalue({ ...formvalue, [name]: updatedValue });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const token = await executeRecaptcha("contact_form");

        const formData = {
            fullname: formvalue.fullname,
            phone: formvalue.phone,
            email: formvalue.email,
            message: formvalue.message,
            token: token,
        };

        try {
            const res = await axios.post("https://projects.bzbeetech.com/react-form/process_form.php", formData);

            if (res.data.success) {
                console.log(res.data.success);
                setFormvalue({
                    fullname: "",
                    phone: "",
                    email: "",
                    message: "",
                });
                handleClick();

                setTimeout(() => {
                    handleClick1();
                }, 3600);
            }
        } catch (error) {
            fail();
        }
        setLoading(false);
    };

    return (
        <>
            <ToastContainer />
            <div className="contact-container">
                <div className="contact_left" data-aos="fade-right">
                    <div className="for_color">
                        <div className="left_inner">
                            <h1 className="contact_title" data-aos="fade-right" data-aos-delay="200">
                                Önskar ni komma i kontakt med oss?
                            </h1>
                            <p data-aos="fade-right" data-aos-delay="200">
                                Har ni behov utav rådgivning eller önskar helt enkelt att hyra några produkter ifrån oss och är osäkra på exakt vad som behövs?{" "}
                            </p>
                            <p data-aos="fade-right" data-aos-delay="200">
                                Tveka då inte att höra av er till oss för att få rådgivning. Enklast är att ta kontakt med oss per telefon men vi svarar även snabbt via mail.{" "}
                            </p>
                            <p className="top_bot" data-aos="fade-right" data-aos-delay="200">
                                Ni kan även ta hjälp utav vårt kontaktformulär här på sidan för att komma i kontakt med oss.{" "}
                            </p>
                            <p data-aos="fade-right" data-aos-delay="300">
                                Kontaktuppgifter:
                            </p>
                            <p data-aos="fade-right" data-aos-delay="300">
                                <a href="">info@hyrljudochljus.nu</a>
                            </p>
                            <p data-aos="fade-right" data-aos-delay="300">
                                Tel: <a href="">070-757 17 00</a>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="contact_right" data-aos="fade-left">
                    <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal">
                        <div className="front">
                            <div className="for_color">
                                <div className="right_inner">
                                    <div className="form-container-main">
                                        <form onSubmit={handleSubmit}>
                                            <div className="form-group" data-aos="fade-left" data-aos-delay="200">
                                                <input type="text" placeholder=" " id="name" name="fullname" value={formvalue.fullname} onChange={handleInput} required />
                                                <label htmlFor="name">
                                                    Namn <span>*</span>
                                                </label>
                                            </div>
                                            <div className="form-group" data-aos="fade-left" data-aos-delay="300">
                                                <input type="text" placeholder=" " id="number" name="phone" value={formvalue.phone} onChange={handleInput} />
                                                <label htmlFor="number">Tel</label>
                                            </div>
                                            <div className="form-group" data-aos="fade-left" data-aos-delay="400">
                                                <input type="email" placeholder=" " id="inputField" name="email" value={formvalue.email} onChange={handleInput} required />
                                                <label htmlFor="inputField">
                                                    E-mail <span>*</span>
                                                </label>
                                            </div>
                                            <div className="form-group" data-aos="fade-left" data-aos-delay="500">
                                                <textarea id="" cols="30" rows="5" placeholder=" " name="message" value={formvalue.message} onChange={handleInput} required></textarea>
                                                <label htmlFor="inputField">
                                                    Meddelande <span>*</span>
                                                </label>
                                            </div>
                                            <div className="btn_main">
                                                <button data-aos="fade-left" data-aos-delay="600" className="submit-button" type="submit" disabled={loading} style={{ cursor: loading ? "not-allowed" : "pointer" }}>
                                                    {loading ? "Sändning..." : "Skicka"} {loading ? "" : <FiSend />}
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="back">
                            <div className="for_color">
                                <div className="right_inner">
                                    <div className="form_img" data-aos="fade-down" data-aos-delay="200">
                                        <img src="./images/success3.gif" alt="" srcset="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ReactCardFlip>
                </div>
            </div>
        </>
    );
};

const App = () => {
    const reCaptchaKey = "6LfuQZApAAAAAEn-IGEvfnpq8TL8yi_D81bKotF0";
    return (
        <GoogleReCaptchaProvider reCaptchaKey={reCaptchaKey}>
            <Contact />
        </GoogleReCaptchaProvider>
    );
};

export default App;
