import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import "./styles/Navbar.css";
import "./../assets/images/SLDRSlogo.png"

function Navbar() {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    showButton();
  }, []);

  window.addEventListener('resize', showButton);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  return (
    <>
      <nav className='navbar'>
        <div className='navbar-container'>
          <NavLink to='/' className='navbar-logo' onClick={closeMobileMenu}>
            <img src='images/SLDRSlogo.png' alt='LOGO'></img>
          </NavLink>
          <div className='menu-icon' onClick={handleClick}>
            <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
          </div>
          <ul className={click ? 'nav-menu active' : 'nav-menu'}>
            <li className='nav-item'>
              <NavLink exact to='/' className='nav-links' activeClassName='active' onClick={() => { closeMobileMenu(); scrollToTop(); }}>
                Hem
              </NavLink>
            </li>
            <li className='nav-item'>
              <NavLink to='/Products' className='nav-links' activeClassName='active' onClick={() => { closeMobileMenu(); scrollToTop(); }}>
                Utrustning
              </NavLink>
            </li>
            <li className='nav-item'>
              <NavLink to='/services' className='nav-links' activeClassName='active' onClick={() => { closeMobileMenu(); scrollToTop(); }}>
                Referenser
              </NavLink>
            </li>
            <li className='nav-item'>
              <NavLink to='/contact-us' className='nav-links' activeClassName='active' onClick={() => { closeMobileMenu(); scrollToTop(); }}>
                KONTAKTA OSS
              </NavLink>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
