import { VerticalCarousel } from "./vertical-carousel/VerticalCarousel";


function Vertical_carousel() {
  const data = [
    {
      images: [
        "./banner/F31.png",
        "./images/50mmspacer.png",
        "./images/F34base30x30.png",
      ],
    },
    {
      images: [
        "./images/F34boxcorner.png",
        "./images/F34050.png",
        "./images/F31.png",
         ],
    },
    {
      images: [
        "./images/F31.png",
        "./images/F34021.png",
        "./images/F31.png",
       ],
    },
    {
      images: [
        "./images/F31.png",
        "./images/Namnlösv55.png",
        "./images/F31.png",
       ],
    },
    {
      images: [
        "./images/F31.png",
        "./images/F31.png",
        "./images/F31.png",
        ],
    },
    {
      images: [
        "./images/F31.png",
        "./images/F31.png",
        "./images/F31.png",
       ],
    },
    {
      images: [
        "./images/F31.png",
        "./images/F31.png",
        "./images/F31.png",
    ],
    },
    {
      images: [
        "./images/F31.png",
        "./images/F31.png",
        "./images/F31.png",
       ],
    },
    {
      images: [
        "./images/F31.png",
        "./images/F31.png",
        "./images/F31.png",
       ],
    },
    {
      images: [
        "./images/F31.png",
        "./images/F31.png",
        "./images/F31.png", ],
    },
    {
      images: [
        "./images/F31.png",
        "./images/F31.png",
        "./images/F31.png", ],
    },
    {
      images: [
        "./images/F31.png",
        "./images/F31.png",
        "./images/F31.png", ],
    },
    {
      images: [
        "./images/F31.png",
        "./images/F31.png",
        "./images/F31.png", ],
    },
    {
      images: [
        "./images/F31.png",
        "./images/F31.png",
        "./images/F31.png", ],
    },
    {
      images: [
        "./images/F31.png",
        "./images/F31.png",
        "./images/F31.png", 
       ],
    },
    {
      images: [
        "./images/F31.png",
        "./images/F31.png",
        "./images/F31.png",
        ],
    },
    {
      images: [
        "./images/F31.png",
        "./images/F31.png",
        "./images/F31.png", ],
    },
    {
      images: [
        "./images/F31.png",
        "./images/F31.png",
        "./images/F31.png",
       ],
    },
    {
      images: [
        "https://www.pricerunner.se/product/150x150/1788138987/Neff-T17TT76N1.jpg?ph=true",
        "https://www.pricerunner.se/product/150x150/3002387244/Neff-KI1812FF0-Integrerad.jpg?ph=true",
        "https://www.pricerunner.se/product/150x150/1554122146/Neff-C17GR00N0-Rostfritt-staal.jpg?ph=true",
      ],
    },
    {
      images: [
        "https://www.pricerunner.se/product/150x150/1788138987/Neff-T17TT76N1.jpg?ph=true",
        "https://www.pricerunner.se/product/150x150/3002387244/Neff-KI1812FF0-Integrerad.jpg?ph=true",
        "https://www.pricerunner.se/product/150x150/1554122146/Neff-C17GR00N0-Rostfritt-staal.jpg?ph=true",
      ],
    },
  ];
  return (
          <VerticalCarousel images={data} />
    
  );
}

export default Vertical_carousel;
