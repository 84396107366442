import React, { useState, useEffect } from "react";
import "@assets/css/Products.css";
import "@assets/css/Contact.css";
import Accordion from "@components/Accordion";
import Product from "@components/Product";
import product_img_menu from "@data/product_img_menu";
import categories from "@data/categories";
import { useNavigate } from "react-router-dom";
import Vertical_banner from "../../components/vertical_banner/vertical_banner";

const Products = () => {
  const history = useNavigate();

  const [category, setCategory] = useState(categories.qualifiedName);
  const [listItems, setListItems] = useState([]);
  const [targetItem, setTargetItem] = useState(null);
  const [path, setPath] = useState(["UTRUSTNING"]);

  const [keyword, setKeyword] = useState("");

  const [productId, setProductId] = useState("");

  const [screenSize, setScreenSize] = useState(getCurrentDimension());

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);

    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [screenSize]);

  useEffect(() => {
    if (keyword) {
      setListItems(
        product_img_menu.filter(
          (item) =>
            (item.product_name &&
              item.product_name.toLowerCase().includes(keyword.toLowerCase())) ||
            (item.producer &&
              item.producer.toLowerCase().includes(keyword.toLowerCase()))
        )
      );
      setPath([`SEARCH for ${keyword}`]);
      setTargetItem(null);
    } else {
      setTargetItem(
        product_img_menu.find(
          (item) => item.product_name?.toLowerCase() === category?.toLowerCase()
        )
      );
      setListItems(
        product_img_menu.filter(
          (item) => item.category?.toLowerCase() === category?.toLowerCase()
        )
      );
    }
  }, [category, keyword]);

  const handleChangeCategory = (item, type = "product") => {
    setKeyword("");
    if (!item) {
      setPath(["UTRUSTNING"]);
      setCategory("UTRUSTNING");
    } else {
      if (type === "category") {
        const parents = item.parents;
        setPath([...parents, item.category]);
        setCategory(item.category);
      } else {
        const pathList = [item.product_name];
        let productCategory = item.category;
        while (productCategory !== "UTRUSTNING") {
          const parent = product_img_menu.find(
            (product) => product.product_name === productCategory
          );
          if (!parent) {
            break;
          }
          pathList.unshift(parent.product_name);
          productCategory = parent.category;
        }
        pathList.unshift("UTRUSTNING");
        setPath(pathList);
        setCategory(item.product_name);

        setProductId(item?.id);
      }
    }
  };

  const handlePathChange = (category) => {
    if (category === "UTRUSTNING") {
      setCategory(category);
      setPath([category]);
    } else {
      const item = product_img_menu.find(
        (product) => product.product_name === category
      );
      handleChangeCategory(item, "path");
    }
  };

  const handleSearch = (e) => {
    const keyword = e.target.value;
    setCategory("UTRUSTNING");
    setKeyword(keyword);
    if (!keyword) {
      setPath(["UTRUSTNING"]);
    }
  };

  const generatePath = (path) => {
    if (screenSize.width > 1200) {
      return path.map((p, index) => {
        if (index === 0) {
          return (
            <span
              onClick={() => handlePathChange(p)}
              className="top-path"
              key={index}
            >
              {p}
            </span>
          );
        } else {
          return (
            <React.Fragment key={p}>
              <span className="mx-1">&gt;</span>
              <span onClick={() => handlePathChange(p)} className="top-path">
                {p}
              </span>
            </React.Fragment>
          );
        }
      });
    }

    const shortPath = path.length > 2 ? path.slice(-2) : path;

    return shortPath.map((p, index) => {
      if (index === 0) {
        return (
          <span
            onClick={() => handlePathChange(p)}
            className="top-path"
            key={index}
          >
            {p}
          </span>
        );
      } else {
        return (
          <React.Fragment key={p}>
            <span className="mx-1">&gt;</span>
            <span onClick={() => handlePathChange(p)} className="top-path">
              {p}
            </span>
          </React.Fragment>
        );
      }
    });
  };

  return (
    <div className="Products">
      <Vertical_banner />  
      <div id="productContainer">
        {screenSize.width > 1200 && (
          <Accordion
            category={category}
            setCategory={handleChangeCategory}
            handleSearch={handleSearch}
            keyword={keyword}
          ></Accordion>
        )}
        <div id="product-card">
          <div className="productheader">
            <div className="productheader_menu">{generatePath(path)}</div>
            {!targetItem || targetItem.level < 4 ? (
              <div className="main_content">
                {listItems.map((item) => (
                  <div className="box" key={item.id}>

                    <div class="bubba-container"  key={item.id}
                          onClick={() => handleChangeCategory(item, "product")}>
                        <figure class="effect-bubba">
                            <img src={item.thumb} alt="" />
                            <figcaption>
                                <div class="heading-text">
                                    <h1>{item.product_name}</h1>
                                    <h4>{item.producer}</h4>
                                </div>
                                {/* <div class="subtext1">
                                    <p>{item.producer}</p>
                                </div> */}
                            </figcaption>			
                        </figure>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <Product productId={productId} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Products;
