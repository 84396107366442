import React from "react";
import "./searchbar.css";
import SearchIcon from "@assets/images/SearchIcon.png";

function Searchbar({ placeholder, handleSearch, keyword }) {
  return (
    <div className="search">
      <div className="searchInputs">
        <input
          type="text"
          placeholder={placeholder}
          onChange={handleSearch}
          value={keyword}
        />
        {/* <div className="searchIcon"></div> */}
      </div>
      <div className="dataResult"></div>
    </div>
  );
}

export default Searchbar;
