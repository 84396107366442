// import { useEffect, useState } from "react";
// import "@assets/css/Products.css";
// import Searchbar from "@components/SearchBar";
// import categories from "@data/categories";
// import product_img_menu from "@data/product_img_menu";

// function Accordion(props) {
//   const shownCategories = categories.subCategories;

//   const [openCategories, setOpenCategories] = useState({});

//   useEffect(() => {
//     let newOpenCategories = {};
//     shownCategories.forEach(cat => {
//       newOpenCategories[cat.category] = false;
//     });
//     setOpenCategories(newOpenCategories);
//   }, [shownCategories]);

//   const toggleCategory = (category) => {
//     setOpenCategories(prevState => ({
//       ...prevState,
//       [category]: !prevState[category]
//     }));
//   };

//   return (
//     <div className="accordion">
//       {shownCategories.map((cat) => (
//         <div className="item" key={cat.category}>
//           <div
//             className="title"
//             onClick={() => toggleCategory(cat.category)}
//           >
//             {cat.category}
//             <span>{openCategories[cat.category] ? "-" : "+"}</span>
//           </div>
//           <div
//             className={
//               openCategories[cat.category] ? "content-show" : "content"
//             }
//           >
//             {cat.subCategories.map((subCat) => (
//               <div
//                 className={
//                   `new-li ` +
//                   (subCat.category?.toLowerCase() === props.level3?.product_name?.toLowerCase() ? "active" : "")
//                 }
//                 key={subCat.category}
//                 onClick={() => props.setCategory(subCat, "category")}
//               >
//                 {subCat.category}
//               </div>
//             ))}
//           </div>
//         </div>
//       ))}
//       <Searchbar
//         placeholder="Search"
//         data={[]}
//         handleSearch={props.handleSearch}
//         keyword={props.keyword}
//       />
//     </div>
//   );
// }

// export default Accordion;

import { useEffect, useState } from "react";
import "@assets/css/Products.css";
import Searchbar from "@components/SearchBar";
import categories from "@data/categories";
import product_img_menu from "@data/product_img_menu";

function Accordion(props) {
  const shownCategories = categories.subCategories;

  const [level2, setLevel2] = useState(null);
  const [level3, setLevel3] = useState(null);
  const [expandedCategory, setExpandedCategory] = useState(null); // Add state to track expanded category

  useEffect(() => {
    let newLevel1 = "";
    let currentProduct = props.category;
    let level2 = "";
    let level3 = "";

    while (!newLevel1) {
      const parent = product_img_menu.find(
        (product) => product.product_name?.toLowerCase() === currentProduct?.toLowerCase()
      );
      if (!parent) {
        break;
      }
      if (parent?.level === 3) {
        level3 = parent;
      }
      if (parent?.level === 2) {
        level2 = parent;
      }
      currentProduct = parent?.category;
    }

    if (level2) {
      setLevel2(level2);
    } else {
      setLevel2(null);
    }

    if (level3) {
      setLevel3(level3);
    } else {
      setLevel3(null);
    }
  }, [props.category]);

  // Function to toggle expanded category
  const toggleCategory = (cat) => {
    if (expandedCategory === cat) {
      setExpandedCategory(null);
    } else {
      setExpandedCategory(cat);
    }
  };

  return (
    <div className="accordion">
      {shownCategories.map((cat) => (
        <div className="item" key={cat.category}>
          <div
            className="title"
            onClick={() => {
              toggleCategory(cat.category); // Toggle the category on click
              props.setCategory(cat, "category");
            }}
          >
            {cat.category}
            <span>{expandedCategory === cat.category ? "-" : "+"}</span>
          </div>
          <div
            className={
              expandedCategory === cat.category ? "content-show" : "content"
            }
          >
            {cat.subCategories.map((subCat) => (
              <div
                className={
                  `new-li ` +
                  (subCat.category?.toLowerCase() === level3?.product_name?.toLowerCase() ? "active" : "")
                }
                key={subCat.category}
                onClick={() => props.setCategory(subCat, "category")}
              >
                {subCat.category}
              </div>
            ))}
          </div>
        </div>
      ))}
      <Searchbar
        placeholder="Search"
        data={[]}
        handleSearch={props.handleSearch}
        keyword={props.keyword}
      />
    </div>
  );
}

export default Accordion;
