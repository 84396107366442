import React, { useState, useRef } from 'react';

// Swiper component
const About = () => {


  return (
    <div  className='About_container'>
        <div class="overlay1"></div>
        <div class="overlay2"></div>
    <div className="about_text">
    
        <div className="about_heading">
        Ditt event
        </div>
        <div className="about_para">
        SLDRS har mer än 20 års erfarenhet av ljud,
        ljus, rigging, och programmering.
        Vi utbildar oss ständigt och investerar
        i ny teknik, för att kunna erbjuda dig
        de lösningar som dagens event förväntar sig
        både i kvalite och pris. Oavsett om det är
        en privat fest för 10 personer eller
        en konsert för 10 000 människor
        så är uppdraget och resultatet lika
        </div>
        <div className="about_heading_last">
        viktigt för oss
        </div>

    </div>
    </div>
  );
};

export default About;
