import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import "@assets/css/Home.css";
import HomeSlider from "@components/HomeSlider";
import About from "../../components/About";
import BrandSlider from "../../components/BrandSlider";

const Home = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [isEnlarged, setIsEnlarged] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 1000); // Show text after 0.5 seconds

    const enlargeTimer = setTimeout(() => {
      setIsEnlarged(true);
    }, 2000); // Enlarge text after 1 additional second

    return () => {
      clearTimeout(timer);
      clearTimeout(enlargeTimer);
    };
  }, []);

  return (
    <div className="container Home-container">
      <div className="hero-image">
        <div className={`hero-text ${isVisible ? 'visible' : ''} ${isEnlarged ? 'enlarged' : ''}`}>
          <h1 className="top_text">HYRLJUDOCHLJUS .NU</h1>
          {/* <h3 className="bott_text">till konsert, mässa teater, event och mycket mer</h3> */}
        </div>
        <div className={`hero-text hero-text1 ${isVisible ? 'visible' : ''} ${isEnlarged ? 'enlarged' : ''}`}>
          {/* <h1 className="top_text">HYRLJUDOCHLJUS .NU</h1> */}
          <h3 className="bott_text">till konsert, mässa teater, event och mycket mer</h3>
        </div>
      </div>
      <div className="slider_div">
      <HomeSlider/>
      </div>
      <div className="About">
        <About/>
      </div>
      <div className="Brand">
        <BrandSlider/>
      </div>
    </div>
  );
};

export default Home;
