import React from 'react';
import "./styles/Footer.css";
import { NavLink } from 'react-router-dom';
function Footer() {
  return (
   <section className='footer-section'>
    <div className='footer-container'>
        <div className='footer_top_text'>
         SOUND LIGHT AND DISCO RENTAL STOCKHOLM
        </div>
        <div className='border-img-top'><img src='images/border-top.png' alt='border' /></div>
        <div className='footer_center_main'>
            <div className='footer_left'>
              <img src='images/lampany.png' alt='footer_lft' />
            </div>
            <div className='footer_right'>
               <div className='footer_inner_flexs'>
                  <div className='flex_box all_menu'>
                      <div className='box_heading'>
                      SLDRS 
                      </div>
                      <div className='box_body '>
                        <p>Thomas Nilsson</p>
                        <p>Barnviksslingan 29</p>
                        <p>139 71 Stavsnäs</p>
                        <p>Tel:<a href="tel:05384637335"> 070-757 17 00</a></p>
                        <p><a href="mailto:info@hyrljudochljus.nu">info@hyrljudochljus.nu</a></p>

                      </div>
                  </div>
                  <div className='flex_box all_menu'>
                      <div className='box_heading'>
                      Sociala medier
                      </div>
                      <div className='box_body social_links'>
                      <a className="fb-h" href="#"  target="_blank">
                        <img width="28" height="28" src="images/facebook.png" alt="facebook"/>
                             <div className='social_text'>Facebook</div>
                      </a>
                      <a  href="#"  target="_blank">
                      <img width="28" height="28" src="images/instagram.png" alt="instagram-new"/>
                             <div className='social_text'>Instagram</div>
                      </a>
                      <a  href="#"  target="_blank">
                      <img width="28" height="28" src="images/youtube.png" alt="youtube-squared"/>
                             <div className='social_text'>Youtube</div>
                      </a>
                      <a  href="#"  target="_blank">
                      <img width="28" height="28" src="images/whatsapp.png" alt="whatsapp-squared"/>
                             <div className='social_text'>Whatsapp</div>
                      </a>
                      </div>
                  </div>
                  <div className='flex_box all_menu'>
                      <div className='box_heading'>
                      Meny
                      </div>
                      <div className='box_body'>
                        <p>   <NavLink exact to='/'   >
                          Hem
                    </NavLink></p>
                        <p><NavLink to='/Products'   >
                      Utrustning
                    </NavLink></p>
                        <p> <NavLink to='/services'   >
                      Referenser
                    </NavLink></p>
                        <p><NavLink to='/contact-us'   >
                      KONTAKTA OSS
                    </NavLink></p>

                      </div>
                  </div>
                  <div className='flex_box all_menu'>
                      <div className='box_heading'>
                      Länkar 
                      </div>
                      <div className='box_body'>
                        <p><a href='#'>GDPR</a> </p>
                        <p><a href='#'>Uthyrningsvilkor</a></p>
                      </div>
                  </div>
               </div>
               <div className='social_main'>
               <div className="share-buttons-container">
        {/* <div className="share-list">
          <a className="fb-h" href="#"  target="_blank">
            <img width="30" height="30" src="images/facebook.png" alt="facebook"/>
          </a>
          <a  href="#"  target="_blank">
          <img width="30" height="30" src="images/instagram.png" alt="instagram-new"/>
          </a>
          <a  href="#"  target="_blank">
          <img width="30" height="30" src="images/youtube.png" alt="youtube-squared"/>
          </a>
          <a  href="#"  target="_blank">
          <img width="30" height="30" src="images/whatsapp.png" alt="youtube-squared"/>
          </a>
       
        </div> */}
      </div>

               </div>
            </div>

        </div>
        <div className='border-img-bottom'><img src='images/border-top.png' alt='border' /></div>
        <div className='footer_bottom_text'>
         SLDRS © 2024
        </div>
    </div>
   </section>
  );
}

export default Footer;
