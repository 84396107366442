import React, { useEffect, useState } from "react";
import ReactCompareImage from "react-compare-image";
import AOS from "aos";
import "aos/dist/aos.css";
// import "./styles.css";
import "@assets/css/Services.css";
const Compare = () => {
  const compareButtonStyle = {
    backgroundColor: '#0173b2',
    borderColor: '#0173b2',
  };

  const [hasError, setHasError] = useState(false);

  const before =
    "./images/cars1.png";
  const after =
    "./images/cars.png";
    useEffect(() => {
        AOS.init();
    }, []);
  return (
    <div className="mainWrapper">
        <div >
        <div className="text_field"  >
          <div data-aos="zoom-in" data-aos-delay="500">  Fran ide till genomforande</div>
        </div>
        <img className="for_mobile" src="./images/cars1.png" alt="" srcset="" />
        </div>
      <div className="contentWrapper">
        {hasError ? (
          <p>Something went wrong.</p>
        ) : (
          <>
            <ReactCompareImage leftImage={before} rightImage={after} compareButtonStyle={compareButtonStyle} />
          </>
        )}
      </div>
    </div>
  );
};

export default Compare;
