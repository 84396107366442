import React from "react";
import "./VerticalCarousel.css";

export const VerticalCarousel = ({ images }) => {
  return (
    <div className="TqLWKQ5WSi pr-zi4fgm" style={{ background: "#232527" }}>
      <div className="pr-1wvi6jk">
        <div className="OrxvcPYXbo pr-lqwo05">
          {images.map((data, index) => (
            <React.Fragment key={index}>
              <div className="UsiG9bXE2_ pr-bq2b8z">
                <a className="pr-noof45" href="#">
                  <div className="O1_JKDC4AK BdrF90TR9L uCtpXdkvvj pr-hxaf01">
                    <div className="pr-15o1nmi">
                      <div className="cHORm9q4_D pr-1cpzyzc">
                        <img
                          data-testid="productImage"
                          className="xUAmIg3IAt pr-0"
                          alt="Produktbild"
                          width="160"
                          height="210"
                          src={data.images[0]}
                        />
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div className="NKJAPDB50i pr-bq2b8z">
                <a className="pr-noof45" href="#">
                  <div className="O1_JKDC4AK BdrF90TR9L uCtpXdkvvj pr-hxaf01">
                    <div className="pr-15o1nmi">
                      <div className="cHORm9q4_D pr-1cpzyzc">
                        <img
                          data-testid="productImage"
                          className="xUAmIg3IAt pr-0"
                          alt="Produktbild"
                          width="160"
                          height="210"
                          src={data.images[1]}
                        />
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div className="UsiG9bXE2_ pr-bq2b8z">
                <a className="pr-noof45" href="#">
                  <div className="O1_JKDC4AK BdrF90TR9L uCtpXdkvvj pr-hxaf01">
                    <div className="pr-15o1nmi">
                      <div className="cHORm9q4_D pr-1cpzyzc">
                        <img
                          data-testid="productImage"
                          className="xUAmIg3IAt pr-0"
                          alt="Produktbild"
                          width="160"
                          height="210"
                          shadow="5px 5px 5 px 5px solid #232527" 
                          src={data.images[2]}
                          srcSet={data.images[2]}
                        />
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};
