import React, { useState, useEffect, useRef } from "react";
import "@assets/css/Products.css";
import Slider from "@components/Slider";
import Accordion from "@components/Accordion";
import product_img_menu from "@data/product_img_menu";
import categories, { categoryByQualifiedName } from "@data/categories";
import { useNavigate } from "react-router-dom";
// import img1 from "../../../public/images/ljud/dvat12.png"
import { useParams } from "react-router-dom";
import { useProductContext } from "@context/productcontex";
import "./SingelProduct.css";
import Gallery from "@components/Gallery";
// import singleList from "@data/single_data.js";
import singleList from "@data/product_img_menu.js";

const Products = ({ productId }) => {
    const content1Ref = useRef(null);
    const content2Ref = useRef(null);
    const content3Ref = useRef(null);
    const content4Ref = useRef(null);

    const scrollToRef = (ref) => {
        ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
    };

    const [activeTab, setActiveTab] = useState(0);
    console.log(productId);
    const handleTabClick = (index) => {
        setActiveTab(index);
    };

    const { getSingleProduct, isSingleLoading, singleProduct } = useProductContext();

    const [product, setProduct] = useState(null);

    useEffect(() => {
        const item = singleList.find((i) => i.id === productId);
        if (item) {
            getSingleProduct(item);
        } else {
            getSingleProduct(null);
        }
        // getSingleProduct("thapaserialnoa")
    }, []);

    if (!singleProduct) {
        return null;
    } else {
        const { product_name, producer, artnr, description, thumb, gallery_img, icons, downloadable_link, dimension, vikt_1, all_cases,all_lists } = singleProduct;
        return (
            <>
                <div className="productcontentareas">
                    <div className="col-sm-6 space_between">
                        <div>
                            <div className="Model">{product_name}</div>
                            <div className="sub-heading">{producer}</div>
                            {/* <div className="Artnr">Artikelnr: {artnr}</div> */}
                            {artnr && <div className="Artnr">Artikelnr: {artnr}</div>}
                            <div className="product_info">{description}</div>
                               {/* all lists */}
                               <div>
                            <ul className="list_style">
                            {all_lists &&
                                            all_lists.map((listss, index) => (
                                                <li key={index}>
                                                   {listss.list_data}
                                                </li>
                                            ))}
                            </ul>
                            </div>
                        </div>
                        {/* information */}
                        <div>
                            <div className="main_technical">
                                <div>
                                    <div className="speaker_right">
                                        <div className="speaker_heading_white">DIMENSIONS ( L X B X H )</div>

                                        <div className="speaker_para">{dimension}</div>
                                        <div className="speaker_heading_white">VIKT</div>
                                        <div className="speaker_para">{vikt_1}</div>

                                        {/* all case */}
                                        <div className="speaker_heading_white">CASE</div>
                                        {all_cases &&
                                            all_cases.map((cases, index) => (
                                                <div key={index}>
                                                    <div className="speaker_para peakesr_case" style={{ fontSize: "16px", marginBottom: "3px" }}>
                                                        {cases.case_info}
                                                    </div>
                                                    <div className="weight_flex">
                                                        <div className="weight_heading">Vikt</div>
                                                        <div className="weight_para">{cases.vikt_2}</div>
                                                    </div>
                                                    <div className="weight_flex" style={{ marginBottom: "15px" }}>
                                                        <div className="weight_heading">Storlek</div>
                                                        <div className="weight_para">{cases.storlek}</div>
                                                    </div>
                                                </div>
                                            ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 img-slide">
                        <div className="productimages">
                            <Gallery thumbnail={thumb} galleryData={gallery_img} />
                        </div>
                        {/* <div className="short_info">Downloads:</div>
          <div className="download_link">
            <a href={pdf}> {pdf} </a>
          </div>
          <br></br>
          <div className="short_info">Package:</div>
          <div className="product_info">{caseinfo}</div>
          <div className="short_info">Tillbehör:</div>
          <div className="product_info">{parts}</div>

          <li>-</li> */}
                    </div>
                </div>

                <div className="productcontentareas ">
                    <div className="col-sm-6">
                        <div className="icons">
                            {icons &&
                                icons.map((img, index) => (
                                    <div class="tooltip-container" key={index}>
                                        <div class="element">
                                            <img src={img.icon}></img>
                                            <span class="tooltip">{img.hovertext}</span>
                                        </div>
                                    </div>
                                ))}
                            {/* <img src="./../images/icons/triggerclamp.png"></img>
            <img src="./../images/icons/xlr3polv4.png"></img>
            <img src="./../images/icons/xlr3polv4.png"></img>
            <img src="./../images/icons/triggerclamp.png"></img>
            <img src="./../images/icons/xlr3polv4.png"></img> */}
                        </div>
                    </div>
                </div>
                <div className="flex_tabs">
                    <div>
                        <div className="tab-content">
                            {/* content3 */}

                            {/* content4 */}
                            <div ref={content4Ref} className="content_main_data">
                                <div className="tab_content_heading">DOWNLOADS</div>
                                <div class="border-img-top"><img src="images/border-top.png" alt="border" style={{marginBottom: "25px"}} /></div>
                                <div className="mains">
                                    <div className="download_main">
                                        <div className="download-left">
                                            {/*  */}

                                            {downloadable_link &&
                                                downloadable_link.map((download, index) => (
                                                    <div className="flex_box_download" key={index}>
                                                        <div className="img_part">
                                                            <div>
                                                                <img src={download.d_img} alt="" />
                                                            </div>
                                                            <div className="down_link">
                                                                <a href={download.d_link} target="_blank">
                                                                    {download.d_heading}
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default Products;
