import React, { useState, useEffect } from 'react';
import "@assets/css/Services.css";
import styled from "styled-components";
import Compare from '../../components/Compare';

function Services() {
  const [disabled, setDisabled] = useState(false);
  const handleClick = () => {
    // Disable the button
    setDisabled(true);

    // After 3 seconds, re-enable the button
    setTimeout(() => {
      setDisabled(false);
    }, 2700);
  };

  const [backgroundImages, setBackgroundImages] = useState([
    './images/background/bg1.jpg',
    './images/background/bg2.jpg',
    './images/background/bg3.jpg',
    './images/background/bg4.jpg',
    './images/image/img5.jpg',
    // Add more background image URLs as needed
  ]);
  const [currentBackgroundIndex, setCurrentBackgroundIndex] = useState(0);

  useEffect(() => {
    const runNextAuto = setTimeout(() => {
      showSlider('next');
    }, 5000); // Adjust auto slide time as needed

    return () => clearTimeout(runNextAuto);
  }, [currentBackgroundIndex]);

  const showSlider = (type) => {
    handleClick();
    const carousel = document.querySelector('.carousel');
    const SliderDom = carousel.querySelector('.carousel .list');
    const thumbnailBorderDom = document.querySelector('.carousel .thumbnail');
    const thumbnailItemsDom = thumbnailBorderDom.querySelectorAll('.item');
    const SliderItemsDom = SliderDom.querySelectorAll('.carousel .list .item');

    if (type === 'next') {
      SliderDom.appendChild(SliderItemsDom[0]);
      thumbnailBorderDom.appendChild(thumbnailItemsDom[0]);
      carousel.classList.add('next');
      setCurrentBackgroundIndex((prevIndex) =>
        (prevIndex + 1) % backgroundImages.length
      );
    } else {
      SliderDom.prepend(SliderItemsDom[SliderItemsDom.length - 1]);
      thumbnailBorderDom.prepend(thumbnailItemsDom[thumbnailItemsDom.length - 1]);
      carousel.classList.add('prev');
      setCurrentBackgroundIndex((prevIndex) =>
        prevIndex === 0 ? backgroundImages.length - 1 : prevIndex - 1
      );
    }

    setTimeout(() => {
      carousel.classList.remove('next');
      carousel.classList.remove('prev');
    }, 600);
   };

  const handleThumbnailClick = (index) => {
    const diff = index - currentBackgroundIndex;
    if (diff !== 0) {
      const type = diff > 0 ? 'next' : 'prev';
      for (let i = 0; i < Math.abs(diff); i++) {
        showSlider(type);
      }
    }
  };

  const getCurrentTime = () => {
    const now = new Date();
    const hours = now.getHours().toString().padStart(2, '0');
    const minutes = now.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
  };

  return (
    <>
      <div className='services-container'>
        <Compare/>
        <div className="carousel">
        <div className="list">
           <div class="item item1">
                <img src="./images/image/img1.jpg" />
                <div class="content">
                    <div class="author">2013</div>
                    <div class="title">ERIC AMARILLO</div>
                    <div class="topic">Ljus, Truss</div>
                    <div class="des">
                        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ut sequi, rem magnam nesciunt minima placeat, itaque eum neque officiis unde, eaque optio ratione aliquid assumenda facere ab et quasi ducimus aut doloribus non numquam. Explicabo, laboriosam nisi reprehenderit tempora.
                    </div>
                  
                </div>
            </div>
            <div class="item item2">
                <img src="./images/image/img2.jpg" />
                <div class="content">
                    <div class="author"></div>
                    <div class="title">QUARNEVALEN ÖLTÄLTET</div>
                    <div class="topic">Scen, Ljud, Ljus, Tekniker</div>
                    <div class="des">
                        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ut sequi, rem magnam nesciunt minima placeat, itaque eum neque officiis unde, eaque optio ratione aliquid assumenda facere ab et quasi ducimus aut doloribus non numquam. Explicabo, laboriosam nisi reprehenderit tempora.
                    </div>
                  
                </div>
            </div>
            <div class="item item3">
                <img src="./images/image/img3.jpg" />
                <div class="content">
                    <div class="author"></div>
                    <div class="title">SQUVALP SLUTFEST</div>
                    <div class="topic">Ljud, Ljus, Rigg, Tekniker</div>
                    <div class="des">
                        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ut sequi, rem magnam nesciunt minima placeat, itaque eum neque officiis unde, eaque optio ratione aliquid assumenda facere ab et quasi ducimus aut doloribus non numquam. Explicabo, laboriosam nisi reprehenderit tempora.
                    </div>
                  
                </div>
            </div>
            <div class="item item4">
                <img src="./images/image/img4.jpg" />
                <div class="content">
                    <div class="author">2023</div>
                    <div class="title">SYSTER SOL</div>
                    <div class="topic">Scen, Ljud, Ljus, Tekniker</div>
                    <div class="des">
                        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ut sequi, rem magnam nesciunt minima placeat, itaque eum neque officiis unde, eaque optio ratione aliquid assumenda facere ab et quasi ducimus aut doloribus non numquam. Explicabo, laboriosam nisi reprehenderit tempora.
                    </div>
                  
                </div>
            </div>
            <div class="item item5">
                <img src="./images/image/img5.jpg" />
                <div class="content">
                    <div class="author">2022</div>
                    <div class="title">NT-Showen</div>
                    <div class="topic">Ljud, Ljus, Video, Tekniker</div>
                    <div class="des">
                        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ut sequi, rem magnam nesciunt minima placeat, itaque eum neque officiis unde, eaque optio ratione aliquid assumenda facere ab et quasi ducimus aut doloribus non numquam. Explicabo, laboriosam nisi reprehenderit tempora.
                    </div>
                  
                </div>
            </div>
        {/* Add other carousel items */}
      </div>
      <div class="thumbnail">
            <div class="item" onClick={() => handleThumbnailClick(0)}>
                <img src="./images/image/img1.jpg" />
                <div class="content">
                    <div class="title">
                        Eric Amarillo
                    </div>
                    <div class="description">
                        2013
                    </div>
                </div>
            </div>
            <div class="item" onClick={() => handleThumbnailClick(1)}>
                <img src="./images/image/img2.jpg" />
                <div class="content">
                    <div class="title">
                        Quarnevalen
                    </div>
                    <div class="description">
                        
                    </div>
                </div>
            </div>
            <div class="item" onClick={() => handleThumbnailClick(2)}>
                <img src="./images/image/img3.jpg" />
                <div class="content">
                    <div class="title">
                        Squvalp
                    </div>
                    <div class="description">
                        
                    </div>
                </div>
            </div>
            <div class="item" onClick={() => handleThumbnailClick(3)}>
                <img src="./images/image/img4.jpg" />
                <div class="content">
                    <div class="title">
                        Syster Sol
                    </div>
                    <div class="description">
                        2023
                    </div>
                </div>
            </div>
            <div class="item" onClick={() => handleThumbnailClick(4)}>
                <img src="./images/image/img5.jpg" />
                <div class="content">
                    <div class="title">
                        NT-showen
                    </div>
                    <div class="description">
                        2022
                    </div>
                </div>
            </div>
        </div>

   
          <div className="arrows">
            <button id="prev" onClick={() => showSlider('prev')}  disabled={disabled}>&lt;</button>
            <button id="next" onClick={() => showSlider('next')}  disabled={disabled}>&gt;</button>
          </div>
          {/* <div className="time"></div> */}
        </div>
      </div>
    </>
  );
}

export default Services;
