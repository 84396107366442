import React, { useState, useEffect } from "react";
import "./ImageStyle.css";

export default function Gallery(props) {
  // Ensure galleryData is defined and is an array
  const Images = props.galleryData ? props.galleryData.flatMap(Object.values) : [];

  const [selectedImg, setSelectedImg] = useState(props.thumbnail);

  useEffect(() => {
    setSelectedImg(props.thumbnail);
  }, [props.thumbnail]);

  const handleImageClick = (img) => {
    setSelectedImg(img);
  };

  return (
    <div className="App">
      <div className="container">
        <img src={selectedImg} alt="Selected" className="selected" />
        <div className="imgContainer">
          {Images.map((img, index) => (
            <div className="more_img" key={index}>
              <img
                src={img}
                alt={`Gallery image ${index + 1}`}
                onClick={() => handleImageClick(img)}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
