import React, { useState, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "../../../node_modules/swiper/swiper-bundle.min.css";
import { Navigation, Pagination, Autoplay } from "swiper/modules";

const ImageSlider = () => {
    const images = [
        {
            src: "./images/ljud/mixerbord.png",
            heading: "LJUD",
            description: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Nisi maiores dolor eius unde cumque praesentium vero, placeat ex error provident eligendi. Laborum optio non ullam earum quas temporibus magnam sequi!",
        },
        {
            src: "./images/ljus/ledbar.png",
            heading: "LJUS",
            description: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Nisi maiores dolor eius unde cumque praesentium vero, placeat ex error provident eligendi. Laborum optio non ullam earum quas temporibus magnam sequi!",
        },
        {
            src: "./images/ljud/djutrustning.png",
            heading: "DJ",
            description: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Nisi maiores dolor eius unde cumque praesentium vero, placeat ex error provident eligendi. Laborum optio non ullam earum quas temporibus magnam sequi!",
        },
        {
            src: "./images/rigging/rigging.png",
            heading: "TRUSS",
            description: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Nisi maiores dolor eius unde cumque praesentium vero, placeat ex error provident eligendi. Laborum optio non ullam earum quas temporibus magnam sequi!",
        },
        {
            src: "./images/ljud/mixerbord.png",
            heading: "LJUD",
            description: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Nisi maiores dolor eius unde cumque praesentium vero, placeat ex error provident eligendi. Laborum optio non ullam earum quas temporibus magnam sequi!",
        },
        {
            src: "./images/ljus/ledbar.png",
            heading: "LJUS",
            description: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Nisi maiores dolor eius unde cumque praesentium vero, placeat ex error provident eligendi. Laborum optio non ullam earum quas temporibus magnam sequi!",
        },
        {
            src: "./images/ljud/djutrustning.png",
            heading: "DJ",
            description: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Nisi maiores dolor eius unde cumque praesentium vero, placeat ex error provident eligendi. Laborum optio non ullam earum quas temporibus magnam sequi!",
        },
        {
            src: "./images/rigging/rigging.png",
            heading: "TRUSS",
            description: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Nisi maiores dolor eius unde cumque praesentium vero, placeat ex error provident eligendi. Laborum optio non ullam earum quas temporibus magnam sequi!",
        },
    ];

    const [hoveredIndex, setHoveredIndex] = useState(null);
    const swiperRef = useRef(null);

    return (
        <div style={{ position: "relative" }}>
            <Swiper
                modules={[Navigation, Pagination, Autoplay]}
                slidesPerView={4}
                slidesPerGroup={4}
                spaceBetween={40}
                autoplay={{
                    delay: 4000,
                    disableOnInteraction: false,
                    pauseOnMouseEnter: true, // This pauses autoplay on hover
                }}
                loop={false}
                speed={1000}
                onSwiper={(swiper) => (swiperRef.current = swiper)}
                breakpoints={{
                    320: {
                        slidesPerView: 1,
                        slidesPerGroup: 1,
                        spaceBetween: 10,
                    },
                    640: {
                        slidesPerView: 2,
                        slidesPerGroup: 2,
                        spaceBetween: 20,
                    },
                    1024: {
                        slidesPerView: 4,
                        slidesPerGroup: 4,
                        spaceBetween: 40,
                    },
                }}
            >
                {images.map((item, index) => (
                    <SwiperSlide
                        key={index}
                        onMouseEnter={() => setHoveredIndex(index)}
                        onMouseLeave={() => setHoveredIndex(null)}
                        style={{ position: "relative" }}
                    >
                        <img
                            src={item.src}
                            alt={`Slide ${index + 1}`}
                            style={{ width: "100%", height: "auto", borderRadius: "15px" }}
                            className="home_slide_img"
                        />
                        <div
                            style={{
                                position: "absolute",
                                top: "0",
                                left: "0",
                                right: "0",
                                bottom: "0",
                                textAlign: "center",
                                zIndex: 1,
                            }}
                            className="text_outer"
                        >
                            <div className={`hover_text ${hoveredIndex === index ? "visible" : ""}`}>
                                <h3>{item.heading}</h3>
                                <p className={`description ${hoveredIndex === index ? "visible" : ""}`}>
                                    {item.description}
                                </p>
                            </div>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
};

export default ImageSlider;
