import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import './vertical_banner.css';
import Vertical_carousel from './vertical_carousel/vertical_carousel';

function VerticalBanner() {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div className='form-container'>
      <div className='form-content-left'>
        <div className='logo_banner' data-aos="fade-right" data-aos-delay="400">UTRUSTNING</div>
        <div className='logo_banner2' data-aos="fade-right" data-aos-delay="500">TILL DITT EVENT</div>
      </div>
      <div className='vertical'>
        <Vertical_carousel />
      </div>
    </div>
  );
}

export default VerticalBanner;
