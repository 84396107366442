import { Link } from "react-router-dom";

const product_img_menu = [
    {
        id: 1,
        category: "UTRUSTNING",
        product_name: "LJUD",
        thumb: "./images/1.png",
        onclick: "ljud/ljudsubmeny",
        level: 2,
    },
    {
        id: 2,
        category: "UTRUSTNING",
        product_name: "LJUS",
        thumb: "./images/ljus/ljus.png",
        level: 2
    },
    {
        id: 3,
        category: "UTRUSTNING",
        product_name: "KABEL",
        thumb: "./images/kabel.png",
        level: 2
    },
    {
        id: 4,
        category: "UTRUSTNING",
        product_name: "Rigging",
        thumb: "./images//rigging/rigging.png",
        level: 2
    },
    {
        id: 5,
        category: "UTRUSTNING",
        product_name: "Övrigt",
        thumb: "./images/no_img.png",
        level: 2
    },
// LJUD
    {
        id: 10,
        category: "LJUD",
        product_name: "LINEARRAY",
        thumb: "./images/ljud/Linearray.png",
        level: 3
    },
    {
        id: 11,
        category: "LJUD",
        product_name: "HÖGTALARE",
        thumb: "./images/no_img.png",
        level: 3
    },
    {
        id: 12,
        category: "LJUD",
        product_name: "Mixerbord",
        thumb: "./images/ljud/mixerbord.png",
        level: 3
    },
    {
        id: 13,
        category: "LJUD",
        product_name: "Signalhantering",
        thumb: "./images/no_img.png",
        level: 3
    },
    {
        id: 14,
        category: "LJUD",
        product_name: "DJ Utrustning",
        thumb: "./images/ljud/djutrustning.png",
        level: 3
    },
    {
        id: 15,
        category: "LJUD",
        product_name: "Mikrofoner",
        thumb: "./images/ljud/mikrofon.png",
        level: 3
    },
    {
        id: 16,
        category: "LJUD",
        product_name: "Trådlösa system",
        thumb: "./images/ljud/wl.png",
        level: 3
    },
    {
        id: 17,
        category: "LJUD",
        product_name: "Lineboxar",
        thumb: "./images/no_img.png",
        level: 3
    },
    {
        id: 18,
        category: "LJUD",
        product_name: "Ljudstativ",
        thumb: "./images/no_img.png",
        level: 3
    },
    {
        id: 19,
        category: "LJUD",
        product_name: "Ljudtillbehör",
        thumb: "./images/no_img.png",
        level: 3
    },

//LJUS
    {
        id: 21,
        category: "LJUS",
        product_name: "Ljusbord",
        thumb: "./images/ljus/ljusbord.png",
        level: 3
    },{
        id: 22,
        category: "LJUS",
        product_name: "Signalhantering",
        thumb: "./images/ljus/signalhantering.png",
        level: 3
    },
    {
        id: 23,
        category: "LJUS",
        product_name: "Moving Heads",
        thumb: "./images/ljus/movingheads.png",
        level: 3
    },
    {
        id: 24,
        category: "LJUS",
        product_name: "Ramper",
        thumb: "./images/ljus/ledbar.png",
        level: 3
    },
    {
        id: 99,
        category: "TILL SALU",
        product_name: "LJUD",
        thumb: "./images/no_img.png",
        level: 2
    },
    {
        id: 25,
        category: "LJUS",
        product_name: "LED",
        thumb: "./images/ljus/ledpar.png",
        level: 3
    },
    {
        id: 26,
        category: "LJUS",
        product_name: "Rökmaskiner",
        thumb: "./images/ljus/smoke.png",
        level: 3
    },
    {
        id: 27,
        category: "LJUS",
        product_name: "Övrigt",
        thumb: "./images/no_img.png",
        level: 3
    },

// KABEL
    {
        id: 51,
        category: "KABEL",
        product_name: "Elcentraler",
        thumb: "./images/no_img.png",
        level: 3
    },
    {
        id: 52,
        category: "KABEL",
        product_name: "3-Fas kabel",
        thumb: "./images/no_img.png",
        level: 3
    },
    {
        id: 53,
        category: "KABEL",
        product_name: "Strömkabel",
        thumb: "./images/no_img.png",
        level: 3
    },
    {
        id: 54,
        category: "KABEL",
        product_name: "Ljudkabel",
        thumb: "./images/no_img.png",
        level: 3
    },
    {
        id: 55,
        category: "KABEL",
        product_name: "DMX kabel",
        thumb: "./images/no_img.png",
        level: 3
    },
    {
        id: 56,
        category: "KABEL",
        product_name: "Nätverkskabel",
        thumb: "./images/no_img.png",
        level: 3
    },
    {
        id: 57,
        category: "KABEL",
        product_name: "BNC kabel",
        thumb: "./images/no_img.png",
        level: 3
    },
    {
        id: 58,
        category: "KABEL",
        product_name: "Övrig kabel",
        thumb: "./images/no_img.png",
        level: 3
    },
    {
        id: 59,
        category: "KABEL",
        product_name: "Kabelskydd",
        thumb: "./images/kabel/kabelskydd.png",
        level: 3
    },

// Rigging
{
    id: 60,
    category: "Rigging",
    product_name: "Lyftredskap",
    thumb: "./images/no_img.png",
    level: 3
},
{
    id: 61,
    category: "Rigging",
    product_name: "F31 Truss",
    thumb: "./images/rigging/F31050B.png",
    level: 3
},
{
    id: 62,
    category: "Rigging",
    product_name: "F32 Truss",
    thumb: "./images/rigging/F32050.png",
    level: 3
},
{
    id: 64,
    category: "Rigging",
    product_name: "F34 Truss",
    thumb: "./images/rigging/F34050.png",
    level: 3
},
{
    id: 65,
    category: "Rigging",
    product_name: "Ground Support",
    thumb: "./images/rigging/F34tower.png",
    level: 3
},
{
    id: 66,
    category: "Rigging",
    product_name: "Specialdelar",
    thumb: "./images/no_img.png",
    level: 3
},
{
    id: 67,
    category: "Rigging",
    product_name: "Övrig Rgging",
    thumb: "./images/no_img.png",
    level: 3
},

// Övrigt
{
    id: 81,
    category: "Övrigt",
    product_name: "Kom-radios",
    thumb: "./images/no_img.png",
    level: 3
},
{
    id: 82,
    category: "Övrigt",
    product_name: "Tyger",
    thumb: "./images/no_img.png",
    level: 3
},


// LINEARRAY
    
        {
            id: 10001,
            level: 4,
            category: "LINEARRAY",
            product_name: "KOMPLETT SYSTEM",
            producer: "DB Technologies",
            artnr: "010-001",
             
            description:
              "Mer information kommer",
            
              all_lists:[
                {
                    list_data:"list data 1",
                },
                {
                    list_data:"list data 2",
                },
                {
                    list_data:"list data 3",
                },
            ],
    
            featured: "true",
            
         //gallery images
             thumb: "./images/ljud/komplete_system.png",
            
             gallery_img:[
                {
                    img:"./images/ljud/dvat12.png",
                },
                {
                    img:"./images/ljud/dvat12back.png",
                },
                {
                    img:"./images/ljud/dvat12case.png",
                },
               
            ] ,
    
             // technical
             dimension:'Beror på systemet',
             vikt_1:'Beror på systemet',
    
           // all cases
           all_cases:[ 
            {
                case_info:'Flygramar, handspel kabel m.m.',
                vikt_2:"70 kg",
                storlek:"1200 x 600 x 600 cm",
            },
            {
                case_info:'För 2 st DVA T12',
                vikt_2:"76 kg",
                storlek:"800 x 600 x 1000 cm",
            },
            {
                case_info:'För 4 st DVA T8',
                vikt_2:"78 kg",
                storlek:"800 x 600 x 1000 cm",
            },
        
         ],
          
            // icons
            icons:[
                {
                    icon:"./images/icons/powercon.png",
                    hovertext:'PowerCon',
                },
                {
                    icon:"./images/icons/rdnet.png",
                    hovertext:'RDNet',
                },
                {
                    icon:"./images/icons/rj45.png",
                    hovertext:'RJ45',
                }
            ] ,
    
          // download
          downloadable_link:[
            {
                d_img:"./images/download.png",
                d_heading:"DVA T12 Manual",
                d_link:"./downloads/DVA T12.pdf"
            },
            {
                d_img:"./images/download.png",
                d_heading:"DVA T12 Datasheet",
                d_link:"./downloads/DVA T12 Datasheet.pdf"
            }
        ],
    },
    
    {
        id: 10-101,
        level: 4,
        category: "LINEARRAY",
        product_name: "DVA T12",
        producer: "DB Technologies",
        artnr: "010-101",

        description:
        "Mer info kommer",

        all_lists:[
            {
                list_data:"list data 1",
            },
            {
                list_data:"list data 2",
            },
            {
                list_data:"list data 3",
            },
        ],

        featured: "true",
         //gallery images
         thumb: "./images/ljud/dvat12.png",
         gallery_img:[
            {
                img:"./images/ljud/dvat12back.png",
            },
            {
                img:"./images/ljud/dvat12back.png",
            },
            {
                img:"./images/ljud/dvat12case.png",
            },
           
        ] ,

         // technical
         dimension:'29 x 29 x 29 cm',
         vikt_1:'30 kg',

       // all cases
       all_cases:[ 
        {
            case_info:'För 2 st DVA T12',
            vikt_2:"76 kg",
            storlek:"800 x 600 x 1000 cm",
        },
    
     ],
      
        // icons
        icons:[
            {
                icon:"./images/icons/powercon.png",
                hovertext:'PowerCon',
            },
            {
                icon:"./images/icons/xlr3polv4.png",
                hovertext:'XLR 3-pol',
            },
            {
                icon:"./images/icons/rdnet.png",
                hovertext:'RDNet',
            },
            {
                icon:"./images/icons/rj45.png",
                hovertext:'RJ45',
            }
        ] ,

      // download
      downloadable_link:[
        {
            d_img:"./images/download.png",
            d_heading:"DVA T12 Manual",
            d_link:"./downloads/DVA T12.pdf"
        },
        {
            d_img:"./images/download.png",
            d_heading:"DVA T12 Datasheet",
            d_link:"./downloads/DVA T12 Datasheet.pdf"
        }
    ],
},

    {
        id: 10-102,
        artnr: "010-102",
        category: "LINEARRAY",
        product_name: "DVA T8",
        producer: "DB Technologies",
        thumb: "./images/ljud/dvat8.png",
        level: 4,
         // 
       description:
       "Mer info kommer",   
       image: "./images/ljud/dvat8.png",
        featured: "true",


         //gallery images
         gallery_img:[
            {

            },
           
        ] ,
         // technical
         dimension:'29 x 29 x 29 cm',
         vikt_1:'30 kg',

       // all cases
       all_cases:[ 
        {
            case_info:'För 4 st DVA T8',
            vikt_2:"76 kg",
            storlek:"800 x 600 x 1000 cm",
        },
        
     ],
    },
    {
        id: 10-122,
        artnr: "010-122",
        category: "LINEARRAY",
        product_name: "DVA S10",
        producer: "DB Technologies",
        thumb: "./images/ljud/dvas10.png",
        level: 4,
         // 
       description:
          "Line array speaker zoom range from narrow 6° beam to wide 50° wash, Uniform illumination of the light cone. Sophisticated fan management with three selectable modes: Silent, Max and Auto. Adjustable pan and tilt range with selectable start and end points. 4 Selectable dimmer curves. Adjustable PWM",
        image: "./images/ljud/dvats10.png",
        featured: "true",
         // technical
         dimension:'29 x 29 x 29 cm',
         vikt_1:'30 kg',

       // all cases
       all_cases:[ 
        {
            case_info:'För 4 st DVA T8',
            vikt_2:"76 kg",
            storlek:"800 x 600 x 1000 cm",
        },
        
     ],
    },
    {
        id: 10-123,
        artnr: "010-123",
        category: "LINEARRAY",
        producer: "DB Technologies",
        product_name: "DVA S30N",
        thumb: "./images/ljud/dvas30n.png",
        level: 4
    },
    {
        id: 10-191,
        artnr: "010-191",
        category: "LINEARRAY",
        product_name: "DRK-10 Flygram",
        producer: "Db Technologies",
        thumb: "./images/ljud/drk-10.png",
        level: 4
    },
    
    {
        id: 10-195,
        artnr: "010-195",
        category: "LINEARRAY",
        product_name: "RDNet Controler 8",
        producer: "Db Technologies",
        thumb: "./images/ljud/rdnet8.png",
        level: 4
    },
    {
        id: 10-199,
        artnr: "010-195",
        category: "LINEARRAY",
        product_name: "LineArray PSU",
        producer: "SLDRS",
        thumb: "./images/no_img.png",
        level: 4
    },
  
// Högtalare
    {
        id: 11-195,
        artnr: "010-555",
        category: "HÖGTALARE",
        product_name: "DVX D8HP",
        producer: "DB Technologies",
        thumb: "./images/ljud/dvx8.png",
        level: 4
    },
    {
        id: 11-194,
        artnr: "010-555",
        category: "HÖGTALARE",
        product_name: "DVX D12HP",
        producer: "DB Technologies",
        thumb: "./images/ljud/dvx12.png",
        level: 4
    },
    {
        id: 11-193,
        artnr: "010-555",
        category: "HÖTALARE",
        product_name: "DVX D15",
        producer: "DB Technologies",
        thumb: "./images/ljud/dvx15.png",
        level: 4
    },
    {
        id: 11-197,
        artnr: "010-555",
        category: "HÖGTALARE",
        product_name: "Opera 405D",
        producer: "DB Technologies",
        thumb: "./images/ljud/opera.png",
        level: 4
    },
    {
        id: 11-198,
        artnr: "010-555",
        category: "HÖGTALARE",
        product_name: "DVX DM15",
        producer: "DB Technologies",
        thumb: "./images/ljud/dvxDM15.png",
        level: 4
    },


    // Mixerbord
    {
        id: 120,
        category: "Mixerbord",
        product_name: "KOMPLETT SYSTEM MED MIXER OCH WL",
        thumb: "./images/no_img.png",
        level: 4
    },
    {
        id: 11-195,
        artnr: "010-195",
        category: "Mixerbord",
        product_name: "LS9 32 ch",
        producer: "Yamaha",
        thumb: "./images/ljud/ls932.png",
        level: 4
    },
    {
        id: 11-196,
        artnr: "010-195",
        category: "Mixerbord",
        product_name: "SQ6",
        producer: "Allen & Heath",
        thumb: "./images/ljud/sq6.png",
        level: 4
    },
    {
        id: 10-197,
        artnr: "010-195",
        category: "Mixerbord",
        product_name: "VLZ 1402",
        thumb: "./images/ljud/vlz1402.png",
        producer: "Mackie",
        level: 4
    },
    {
        id: 10-198,
        artnr: "010-198",
        category: "Mixerbord",
        product_name: "SB168",
        thumb: "./images/ljud/sb168.png",
        producer: "Yamaha",
        level: 4
    },

    // DJ Utrustning
    {
        id: 13-101,
        artnr: "010-198",
        category: "DJ Utrustning",
        product_name: "Mixdeck",
        thumb: "./images/ljud/mixdeck.png",
        producer: "Numark",
        level: 4
    },
    {
        id: 13-102,
        artnr: "010-198",
        category: "DJ Utrustning",
        product_name: "HD2500",
        thumb: "./images/ljud/hd2500.png",
        producer: "Denon",
        level: 4
    },

// Mikrofoner
    {
        id: 15-121,
        category: "Mikrofoner",
        product_name: "Shure SM58",
        level: 4,
        producer: "Mikrofoner",
        artnr: "015-121",
        description:
          "Shure mikrofon..Line array speaker zoom range from narrow 6° beam to wide 50° wash, Uniform illumination of the light cone. Sophisticated fan management with three selectable modes: Silent, Max and Auto. Adjustable pan and tilt range with selectable start and end points. 4 Selectable dimmer curves. Adjustable PWM",
          
          all_lists:[
            {
                list_data:"list data 1",
            },
            {
                list_data:"list data 2",
            },
            {
                list_data:"list data 3",
            },
        ],
        
          pdf: "./../assets/manuals/Varytec.pdf",
        featured: "true",
        thumb: "./images/ljud/sm58.png",
        //gallery images
        gallery_img:[
            {
                img:"./images/ljud/sm58.png",
            },
            {
                img:"./images/ljud/beta57.png",
            },
            {
                img:"./images/ljud/dvx12.png",
            },
            {
                img:"./images/ljud/Co9.png",
            },
            {
                img:"./images/ljud/dvx12.png",
            },
            {
                img:"./images/ljud/Co9.png",
            },
            {
                img:"./images/ljud/Co9.png",
            },
            {
                img:"./images/ljud/Co9.png",
            },
            {
                img:"./images/ljud/dvx12.png",
            },
           
        ] ,

        // technical
        dimension:'29 x 29 x 29 cm',
        vikt_1:'8,3 kg',

        // all cases
        all_cases:[ 
            {
            case_info:'För 2 st Mikrofoner Shure SM58',
            vikt_2:"22 kg",
            storlek:"600 x 600 x 1200 cm",
            },
            {
            case_info:'För 4 st Mikrofoner Shure SM58',
            vikt_2:"44 kg",
            storlek:"1600 x 1600 x 1200 cm",
            },
         ],
         
        // icons
        icons:[
            {
                icon:"./images/icons/triggerclamp.png",
                hovertext:'Trigger clamp',
            },
            {
                icon:"./images/icons/xlr3polv4.png",
                hovertext:'DMX 3-pol',
            }
        ] ,
        // download
        downloadable_link:[
            {
                d_img:"./images/download.png",
                d_heading:"Shure SM58 Manual",
                d_link:"#"
            },
            {
                d_img:"./images/download.png",
                d_heading:"Shure SM58 Drawing",
                d_link:"#"
            }
        ],
    },
    {
        id: 15-122,
        category: "Mikrofoner",
        product_name: "Shure Beta58",
        thumb: "./images/ljud/beta58.png",
        level: 4
    },
    {
        id: 15-131,
        category: "Mikrofoner",
        product_name: "Electro Voice Co9",
        thumb: "./images/ljud/Co9.png",
        level: 4
    },
    {
        id: 15-132,
        category: "Mikrofoner",
        product_name: "Electro Voice PL44",
        thumb: "./images/ljud/pl44.png",
        level: 4
    },
     {
        id: 15-223,
        category: "Mikrofoner",
        product_name: "Shure SM57",
        thumb: "./images/ljud/sm57.png",
        level: 4
    },
    {
        id: 15-222,
        category: "Mikrofoner",
        product_name: "Shure Beta57",
        thumb: "./images/ljud/beta57.png",
        level: 4
    },
    {
        id: 15-241,
        category: "Mikrofoner",
        product_name: "AKG C391",
        thumb: "./images/ljud/c391.png",
        level: 4
    },
    {
        id: 15-251,
        category: "Mikrofoner",
        product_name: "Line Audio CM3",
        thumb: "./images/ljud/cm3.png",
        level: 4
    },
    {
        id: 15-311,
        category: "Mikrofoner",
        product_name: "Sennheiser e604",
        thumb: "./images/ljud/e604.png",
        level: 4
    },
    {
        id: 15-312,
        category: "Mikrofoner",
        product_name: "Sennheiser md421",
        thumb: "./images/ljud/md421.png",
        level: 4
    },
    {
        id: 15-313,
        category: "Mikrofoner",
        product_name: "Sennheiser e906",
        thumb: "./images/ljud/e906.png",
        level: 4
    },
    {
        id: 15-421,
        category: "Mikrofoner",
        product_name: "Shure Beta 52A",
        thumb: "./images/ljud/beta52.png",
        level: 4
    },
    {
        id: 15-501,
        category: "Mikrofoner",
        product_name: "DPA 4061",
        thumb: "./images/ljud/dpa4061.png",
        level: 4
    },
    {
        id: 15-511,
        category: "Mikrofoner",
        product_name: "DPA 4066",
        thumb: "./images/ljud/dpa4066.png",
        level: 4
    },
    {
        id: 15-521,
        category: "Mikrofoner",
        product_name: "Audac CMX726S",
        thumb: "./images/ljud/cmx726.png",
        level: 4
    },
   
    {
        id: 15-601,
        category: "Mikrofoner",
        product_name: "Superlux PRA-383D",
        thumb: "./images/ljud/pra383.png",
        level: 4
    },
    {
        id: 15-821,
        category: "Mikrofoner",
        product_name: "Shure Super 55SH-BCR",
        thumb: "./images/ljud/sh55.png",
        level: 4
    },
    {
        id: 15-901,
        category: "Mikrofoner",
        product_name: "T.bone MM-1",
        thumb: "./images/ljud/mm-1.png",
        level: 4
    },
    {
        id: 35-121,
        category: "Moving Heads",
        product_name: "ACL 360 Bar",
        level: 4,
        producer: "Elation",
        artnr: "032-121",
        description:
          "Shure mikrofon..Line array speaker zoom range from narrow 6° beam to wide 50° wash, Uniform illumination of the light cone. Sophisticated fan management with three selectable modes: Silent, Max and Auto. Adjustable pan and tilt range with selectable start and end points. 4 Selectable dimmer curves. Adjustable PWM",
        pdf: "./../assets/manuals/Varytec.pdf",
        featured: "true",
        thumb: "./images/ljud/sm58.png",
        //gallery images
        gallery_img:[
            {
                img:"./images/ljud/sm58.png",
            },
            {
                img:"./images/ljud/beta57.png",
            },
            {
                img:"./images/ljud/beta58.png",
            },
            {
                img:"./images/ljud/Co9.png",
            },
            {
                img:"./images/ljud/Co9.png",
            },
            {
                img:"./images/ljud/Co9.png",
            },
            {
                img:"./images/ljud/Co9.png",
            },
            {
                img:"./images/ljud/Co9.png",
            },
            {
                img:"./images/ljud/Co9.png",
            },
           
        ] ,

        // technical
        dimension:'29 x 29 x 29 cm',
        vikt_1:'8,3 kg',

        // all cases
        all_cases:[ 
            {
            case_info:'För 2 st Mikrofoner Shure SM58',
            vikt_2:"22 kg",
            storlek:"600 x 600 x 1200 cm",
            },
            {
            case_info:'För 4 st Mikrofoner Shure SM58',
            vikt_2:"44 kg",
            storlek:"1600 x 1600 x 1200 cm",
            },
         ],
         
        // icons
        icons:[
            {
                icon:"./images/icons/triggerclamp.png",
                hovertext:'Trigger clamp',
            },
            {
                icon:"./images/icons/xlr3polv4.png",
                hovertext:'DMX 3-pol',
            }
        ] ,
        // download
        downloadable_link:[
            {
                d_img:"./images/download.png",
                d_heading:"Shure SM58 Manual",
                d_link:"#"
            },
            {
                d_img:"./images/download.png",
                d_heading:"Shure SM58 Drawing",
                d_link:"#"
            },
        ],
    },
    {
        id: 11-185,
        artnr: "010-555",
        category: "Lineboxar",
        product_name: "IMP2",
        producer: "Whirlwind",
        thumb: "./images/no_img.png",
        level: 4
    },
    {
        id: 18-165,
        artnr: "010-555",
        category: "Lineboxar",
        product_name: "AR133",
        producer: "BSS",
        thumb: "./images/no_img.png",
        level: 4
    },
    {
        id: 17-193,
        artnr: "010-555",
        category: "Lineboxar",
        product_name: "PcDI",
        producer: "Whirl Wind",
        thumb: "./images/no_img.png",
        level: 4
    },
    {
        id: 18-1911,
        artnr: "010-555",
        category: "Lineboxar",
        product_name: "Bluetooth",
        producer: "Denon",
        thumb: "./images/no_img.png",
        level: 4
    },

    // LJUS
    {
        id: 32-101,
        level: 4,
        category: "LED",
        product_name: "2 Bright Blind 3k IP",
        producer: "Ignition",
        artnr: "032-101",
        
        description:
        "En klassisk armatur för livekonserter m.m. Används ofta för att blända publiken eller låta glöda när man vill att publiken skall sjunga med ",  
        
        all_lists:[
            {
                list_data:"LED: 2 x 100 W Varmvit",
            },
            {
                list_data:"Spridning: 90°",
            },
            {
                list_data:"CRI: >90",
            },
            {
                list_data:"Färgtemperatur: 3000K",
            },
            {
                list_data:"DMX kanaler: 1/2/3/6",
            },
            {
                list_data:"IP: IP65",
            },
            {
                list_data:"Ström: True1 In / Ut",
            },
            {
                list_data:"DMX: XLR 3-pol In / Ut",
            },



        ],

        thumb: "./images/ljus/blinder.png",
        featured: "true",
         //gallery images

         gallery_img:[
            {
                img:"./images/ljus/blinder_back.png",
            },
            {
                img:"./images/ljus/blinder_case.png",
            },
            
           
        ] ,

         // technical
         dimension:'400 x 230 x 260 mm',
         vikt_1:'9 kg',

       // all cases
       all_cases:[ 
        {
            case_info:'För 2 st Blinders',
            vikt_2:"44 kg",
            storlek:"595 x 595 x 480 mm",
        },
    
     ],
      
        // icons
        icons:[
            {
                icon:"./images/icons/true1.png",
                hovertext:'True1',
            },
            {
                icon:"./images/icons/led.png",
                hovertext:'LED lampa',
            },
            
            {
                icon:"./images/icons/xlr3polv4.png",
                hovertext:'XLR 3-pol',
            },
            {
                icon:"./images/icons/triggerclamp.png",
                hovertext:'Trigger clamp',
            },
            {
                icon:"./images/icons/ip65.png",
                hovertext:'IP65',
            }
        ] ,

      // download
      downloadable_link:[
        {
            d_img:"./images/download.png",
            d_heading:"Ignition 2 Bright Blinder Manual",
            d_link:"./downloads/ignition_blinder.pdf"
        },
       
    ],
},
    {
        id: 32-520,
        level: 4,
        category: "LED",
        product_name: "Pro Slim Par 18 TLC RBG",
        producer: "Futurelight",
        artnr: "032-520",
        
        description:
        "En klassisk LED lampa för att färga in. Varje diod är Röd, Grön och Blå. Kan används både som scenljus eller miljöljus", 
        all_lists:[
            {
                list_data:"Antal dioder: 18 st",
            },
            {
                list_data:"Diodtyp: 3 Watt TLC RGB diod",
            },
            {
                list_data:"Spridning: 18°",
                
            },
            {
                list_data:"DMX: 3-pol XLR In / Ut",    
            },
            {
                list_data:"DMX kanaler: 3/4/5/9", 
            },
            {
                list_data:"Musikstyrning: Har inbyggd mikrofon",
            },
            {
                list_data:"Max Effekt: 55 Watt",
            },
            {
                list_data:"Ström: PowerCon In / Ut",
            },
        ],
  
        
        featured: "true",
        
        //gallery images
         thumb: "./images/ljus/slimpar18.png",
         gallery_img:[
            {
                img:"./images/ljus/slimpar18.png",
            },
            {
                img:"./images/ljus/slimpar18_back.png",
            },
            
           
        ] ,

         // technical
         dimension:'263 x 100 x 300 mm',
         vikt_1:'4 kg',

       // all cases
       all_cases:[ 
        {
            case_info:'För 8 st Pro Slim Par',
            vikt_2:"64 kg",
            storlek:"900 x 600 x 600 mm",
        },
    
     ],
      
        // icons
        icons:[
            {
                icon:"./images/icons/powercon.png",
                hovertext:'PowerCon',
            },
            {
                icon:"./images/icons/led.png",
                hovertext:'LED lampa',
            },
            {
                icon:"./images/icons/rgb.png",
                hovertext:'Röd Grön Blå',
            },
            
            {
                icon:"./images/icons/xlr3polv4.png",
                hovertext:'XLR 3-pol',
            },
            
            {
                icon:"./images/icons/sound.png",
                hovertext:'Ljudstyrd',
            },
          
          
        ] ,

      // download
      downloadable_link:[
        {
            d_img:"./images/download.png",
            d_heading:"Futurelight Pro Slim Par 18 TLC manual",
            d_link:"./downloads/slimpar18.pdf"
        },
       
    ],
},


{
    id: 32-520,
    level: 4,
    category: "LED",
    product_name: "Pro Slim Par 18 TLC RBG",
    producer: "Futurelight",
    artnr: "032-520",
    
    description:
    "En klassisk LED lampa för att färga in. Varje diod är Röd, Grön och Blå. Kan används både som scenljus eller miljöljus", 
    all_lists:[
        {
            list_data:"Antal dioder: 18 st",
        },
        {
            list_data:"Diodtyp: 3 Watt TLC RGB diod",
        },
        {
            list_data:"Spridning: 18°",
            
        },
        {
            list_data:"DMX: 3-pol XLR In / Ut",    
        },
        {
            list_data:"DMX kanaler: 3/4/5/9", 
        },
        {
            list_data:"Musikstyrning: Har inbyggd mikrofon",
        },
        {
            list_data:"Max Effekt: 55 Watt",
        },
        {
            list_data:"Ström: PowerCon In / Ut",
        },
    ],

    
    featured: "true",
    
    //gallery images
     thumb: "./images/ljus/slimpar18.png",
     gallery_img:[
        {
            img:"./images/ljus/slimpar18.png",
        },
        {
            img:"./images/ljus/slimpar18_back.png",
        },
        
       
    ] ,

     // technical
     dimension:'263 x 100 x 300 mm',
     vikt_1:'4 kg',

   // all cases
   all_cases:[ 
    {
        case_info:'För 8 st Pro Slim Par',
        vikt_2:"64 kg",
        storlek:"900 x 600 x 600 mm",
    },

 ],
  
    // icons
    icons:[
        {
            icon:"./images/icons/powercon.png",
            hovertext:'PowerCon',
        },
        {
            icon:"./images/icons/led.png",
            hovertext:'LED lampa',
        },
        {
            icon:"./images/icons/rgb.png",
            hovertext:'Röd Grön Blå',
        },
        
        {
            icon:"./images/icons/xlr3polv4.png",
            hovertext:'XLR 3-pol',
        },
        
        {
            icon:"./images/icons/sound.png",
            hovertext:'Ljudstyrd',
        },
      
      
    ] ,

  // download
  downloadable_link:[
    {
        d_img:"./images/download.png",
        d_heading:"Futurelight Pro Slim Par 18 TLC manual",
        d_link:"./downloads/slimpar18.pdf"
    },
   
],
},



{
    id: 55-301,
    category: "3-Fas kabel",
    producer: "Malmbergs",
    product_name: "3-Fas kabel 32 A 10m",
    thumb: "./images/ljus/slimpar36.png",
    level: 4,
    // 
    artnr: "055-301",
    description:
    "Mer info kommer",   
    
    featured: "true",
     //gallery images
     gallery_img:[
        {
            img:"./images/ljus/slimpar36.png",
        },
        {
            img:"./images/ljus/slimpar36_back.png",
        },
        
       
    ] ,

     // technical
     dimension:'263 x 100 x 300 mm',
     vikt_1:'4 kg',

   // all cases
   all_cases:[ 
    {
        case_info:'För 8 st Pro SLim Par',
        vikt_2:"64 kg",
        storlek:"900 x 600 x 600 mm",
    },

 ],
  
    // icons
    icons:[
        {
            icon:"./images/icons/powercon.png",
            hovertext:'PowerCon',
        },
        {
            icon:"./images/icons/led.png",
            hovertext:'LED lampa',
        },
        {
            icon:"./images/icons/wwcw.png",
            hovertext:'Varm/Kall vit',
        },
        
        {
            icon:"./images/icons/xlr3polv4.png",
            hovertext:'XLR 3-pol',
        },
      
      
    ] ,

  // download
  downloadable_link:[
    {
        d_img:"./images/download.png",
        d_heading:"Futurelight Pro Slim Par 36 manual",
        d_link:"./downloads/slimpar36.pdf"
    },
   
],
},
{
    id: 231,
    category: "Moving Heads",
    producer: "nooon",
    product_name: "Elation ACL 360 Bar",
    thumb: "./images/2.png",
    level: 4,
},
{
    

    id: 232,
    category: "Moving Heads",
    producer: "Varytec",
    product_name: "Hero Wash 300 FC",
    thumb: "./images/2.png",
    level: 4,

},
{
    id: 10,
    category: "Ramper",
    product_name: "Stairville Elation ACL 360 Bar",
    thumb: "./images/2.png",
    level: 4
},
{
    id: 55-301,
    category: "3-Fas kabel",
    producer: "Malmbergs",
    product_name: "3-Fas kabel 32 A 10m",
    thumb: "./images/ljus/slimpar36.png",
    level: 4,
    // 
    artnr: "055-301",
    description:
    "Mer info kommer",   
    
    featured: "true",
     //gallery images
     gallery_img:[
        {
            img:"./images/ljus/slimpar36.png",
        },
        {
            img:"./images/ljus/slimpar36_back.png",
        },
        
       
    ] ,

     // technical
     dimension:'263 x 100 x 300 mm',
     vikt_1:'4 kg',

   // all cases
   all_cases:[ 
    {
        case_info:'För 8 st Pro SLim Par',
        vikt_2:"64 kg",
        storlek:"900 x 600 x 600 mm",
    },

 ],
  
    // icons
    icons:[
        {
            icon:"./images/icons/powercon.png",
            hovertext:'PowerCon',
        },
        {
            icon:"./images/icons/led.png",
            hovertext:'LED lampa',
        },
        {
            icon:"./images/icons/wwcw.png",
            hovertext:'Varm/Kall vit',
        },
        
        {
            icon:"./images/icons/xlr3polv4.png",
            hovertext:'XLR 3-pol',
        },
      
      
    ] ,

  // download
  downloadable_link:[
    {
        d_img:"./images/download.png",
        d_heading:"Futurelight Pro Slim Par 36 manual",
        d_link:"./downloads/slimpar36.pdf"
    },
   
],
},
{
    id: 231,
    category: "Moving Heads",
    producer: "nooon",
    product_name: "Elation ACL 360 Bar",
    thumb: "./images/2.png",
    level: 4,
},
{
    

    id: 232,
    category: "Moving Heads",
    producer: "Varytec",
    product_name: "Hero Wash 300 FC",
    thumb: "./images/2.png",
    level: 4,

},
// TRUSS

//F31
{
    id: 61050,
    category: "F31 Truss",
    producer: "Global Truss",
    product_name: "F31 50 cm Black",
    thumb: "./images/rigging/F31050B.png",
    level: 4,
    // 
    artnr: "061-050",
    description:
    "Levereras med koner, sprint och R-clips",   
    
    featured: "true",
     //gallery images
     

     // technical
     dimension:'500 x 50 x 50 mm',
     vikt_1:'1,1 kg',

   // all cases
   all_cases:[ 
    {
        case_info:'',
        vikt_2:"",
        storlek:"",
    },

 ],
  
    
  // download
  downloadable_link:[
    {
        d_img:"./images/download.png",
        d_heading:"F31 Datablad",
        d_link:"./downloads/F31datablad.pdf"
    },
   
],
},
{
    id: 61051,
    category: "F31 Truss",
    producer: "Global Truss",
    product_name: "F31 50 cm Alu",
    thumb: "./images/rigging/F31050A.png",
    level: 4,
    // 
    artnr: "061-050A",
    description:
    "Levereras med koner, sprint och R-clips",   
    
    featured: "true",
     //gallery images
     

     // technical
     dimension:'500 x 50 x 50 mm',
     vikt_1:'1,1 kg',

   // all cases
   all_cases:[ 
    {
        case_info:'',
        vikt_2:"",
        storlek:"",
    },

 ],
  
    
  // download
  downloadable_link:[
    {
        d_img:"./images/download.png",
        d_heading:"F31 Datablad",
        d_link:"./downloads/F31datablad.pdf"
    },
   
],
},
{
    id: 61100,
    category: "F31 Truss",
    producer: "Global Truss",
    product_name: "F31 100 cm Black",
    thumb: "./images/rigging/F31100B.png",
    level: 4,
    // 
    artnr: "061-100",
    description:
    "Levereras med koner, sprint och R-clips",   
    
    featured: "true",
     //gallery images
     

     // technical
     dimension:'1000 x 50 x 50 mm',
     vikt_1:'1,4 kg',

   // all cases
   all_cases:[ 
    {
        case_info:'',
        vikt_2:"",
        storlek:"",
    },

 ],
  
    
  // download
  downloadable_link:[
    {
        d_img:"./images/download.png",
        d_heading:"F31 Datablad",
        d_link:"./downloads/F31datablad.pdf"
    },
   
],
},
{
    id: 61101,
    category: "F31 Truss",
    producer: "Global Truss",
    product_name: "F31 100 cm Alu",
    thumb: "./images/rigging/F31100A.png",
    level: 4,
    // 
    artnr: "061-101A",
    description:
    "Levereras med koner, sprint och R-clips",   
    
    featured: "true",
     //gallery images
     

     // technical
     dimension:'1000 x 50 x 50 mm',
     vikt_1:'1,4 kg',

   // all cases
   all_cases:[ 
    {
        case_info:'',
        vikt_2:"",
        storlek:"",
    },

 ],
  
    
  // download
  downloadable_link:[
    {
        d_img:"./images/download.png",
        d_heading:"F31 Datablad",
        d_link:"./downloads/F31datablad.pdf"
    },
   
],
},
{
    id: 61150,
    category: "F31 Truss",
    producer: "Global Truss",
    product_name: "F31 150 cm Black",
    thumb: "./images/rigging/F31150B.png",
    level: 4,
    // 
    artnr: "061-150",
    description:
    "Levereras med koner, sprint och R-clips",   
    
    featured: "true",
     //gallery images
     

     // technical
     dimension:'1500 x 50 x 50 mm',
     vikt_1:'1,9 kg',

   // all cases
   all_cases:[ 
    {
        case_info:'',
        vikt_2:"",
        storlek:"",
    },

 ],
  
    
  // download
  downloadable_link:[
    {
        d_img:"./images/download.png",
        d_heading:"F31 Datablad",
        d_link:"./downloads/F31datablad.pdf"
    },
   
],
},
{
    id: 61151,
    category: "F31 Truss",
    producer: "Global Truss",
    product_name: "F31 150 cm Alu",
    thumb: "./images/rigging/F31150A.png",
    level: 4,
    // 
    artnr: "061-150A",
    description:
    "Levereras med koner, sprint och R-clips",   
    
    featured: "true",
     //gallery images
     

     // technical
     dimension:'1500 x 50 x 50 mm',
     vikt_1:'1,9 kg',

   // all cases
   all_cases:[ 
    {
        case_info:'',
        vikt_2:"",
        storlek:"",
    },

 ],
  
    
  // download
  downloadable_link:[
    {
        d_img:"./images/download.png",
        d_heading:"F31 Datablad",
        d_link:"./downloads/F31datablad.pdf"
    },
   
],
},
{
    id: 61200,
    category: "F31 Truss",
    producer: "Global Truss",
    product_name: "F31 200 cm Black",
    thumb: "./images/rigging/F31200B.png",
    level: 4,
    // 
    artnr: "061-200",
    description:
    "Levereras med koner, sprint och R-clips",   
    
    featured: "true",
     //gallery images
     

     // technical
     dimension:'2000 x 50 x 50 mm',
     vikt_1:'2,4 kg',

   // all cases
   all_cases:[ 
    {
        case_info:'',
        vikt_2:"",
        storlek:"",
    },

 ],
  
    
  // download
  downloadable_link:[
    {
        d_img:"./images/download.png",
        d_heading:"F31 Datablad",
        d_link:"./downloads/F31datablad.pdf"
    },
   
],
},
{
id: 61201,
category: "F31 Truss",
producer: "Global Truss",
product_name: "F31 200 cm Alu",
thumb: "./images/rigging/F31200A.png",
level: 4,
// 
artnr: "061-200A",
description:
"Levereras med koner, sprint och R-clips",   

featured: "true",
 //gallery images
 

 // technical
 dimension:'2000 x 50 x 50 mm',
 vikt_1:'2,4 kg',

// all cases
all_cases:[ 
{
    case_info:'',
    vikt_2:"",
    storlek:"",
},

],


// download
downloadable_link:[
{
    d_img:"./images/download.png",
    d_heading:"F31 Datablad",
    d_link:"./downloads/F31datablad.pdf"
},

],
},
{
    id: 61300,
    category: "F31 Truss",
    producer: "Global Truss",
    product_name: "F31 300 cm Black",
    thumb: "./images/rigging/F31300B.png",
    level: 4,
    // 
    artnr: "061-300",
    description:
    "Levereras med koner, sprint och R-clips",   
    
    featured: "true",
     //gallery images
     

     // technical
     dimension:'3000 x 50 x 50 mm',
     vikt_1:'3 kg',

   // all cases
   all_cases:[ 
    {
        case_info:'',
        vikt_2:"",
        storlek:"",
    },

 ],
  
    
  // download
  downloadable_link:[
    {
        d_img:"./images/download.png",
        d_heading:"F31 Datablad",
        d_link:"./downloads/F31datablad.pdf"
    },
   
],
},
{
    id: 61601,
    category: "F31 Truss",
    producer: "Global Truss",
    product_name: "F31 Truss cube Black",
    thumb: "./images/rigging/F31cubeB.png",
    level: 4,
    // 
    artnr: "061-600",
    description:
    "Levereras med koner, sprint och R-clips",   
    
    featured: "true",
     //gallery images
     

     // technical
     dimension:'50 x 50 x 50 mm',
     vikt_1:'0,8 kg',

   // all cases
   all_cases:[ 
    {
        case_info:'',
        vikt_2:"",
        storlek:"",
    },

 ],
  
    
  // download
  downloadable_link:[
    {
        d_img:"./images/download.png",
        d_heading:"F31 Datablad",
        d_link:"./downloads/F31datablad.pdf"
    },
   
],
},
{
    id: 61602,
    category: "F31 Truss",
    producer: "Global Truss",
    product_name: "F31 Truss cube Alu",
    thumb: "./images/rigging/F31cubeA.png",
    level: 4,
    // 
    artnr: "061-600A",
    description:
    "Levereras med koner, sprint och R-clips",   
    
    featured: "true",
     //gallery images
     

     // technical
     dimension:'50 x 50 x 50 mm',
     vikt_1:'0,8 kg',

   // all cases
   all_cases:[ 
    {
        case_info:'',
        vikt_2:"",
        storlek:"",
    },

 ],
  
    
  // download
  downloadable_link:[
    {
        d_img:"./images/download.png",
        d_heading:"F31 Datablad",
        d_link:"./downloads/F31datablad.pdf"
    },
   
],
},
{
    id: 61635,
    category: "F31 Truss",
    producer: "Global Truss",
    product_name: "F31 135 Truss Cube Black",
    thumb: "./images/rigging/F31cube135B.png",
    level: 4,
    // 
    artnr: "061-635",
    description:
    "Levereras med koner, sprint och R-clips",   
    
    featured: "true",
     //gallery images
     

     // technical
     dimension:'500 x 50 x 50 mm',
     vikt_1:'1,1 kg',

   // all cases
   all_cases:[ 
    {
        case_info:'',
        vikt_2:"",
        storlek:"",
    },

 ],
  
    
  // download
  downloadable_link:[
    {
        d_img:"./images/download.png",
        d_heading:"F31 Datablad",
        d_link:"./downloads/F31datablad.pdf"
    },
   
],
},

//F32
{
    id: 62050,
    category: "F32 Truss",
    producer: "Global Truss",
    product_name: "F32 50 cm Black",
    thumb: "./images/rigging/F32050.png",
    level: 4,
    // 
    artnr: "062-050",
    description:
    "Levereras med koner, sprint och R-clips",   
    
    featured: "true",
     //gallery images
     

     // technical
     dimension:'500 x 50 x 290 mm',
     vikt_1:'1,7 kg',

   // all cases
   all_cases:[ 
    {
        case_info:'',
        vikt_2:"",
        storlek:"",
    },

 ],
  
    
  // download
  downloadable_link:[
    {
        d_img:"./images/download.png",
        d_heading:"F32 Datablad",
        d_link:"./downloads/F32datablad.pdf"
    },
   
],
},
{
    id: 62075,
    category: "F32 Truss",
    producer: "Global Truss",
    product_name: "F32 75 cm Black",
    thumb: "./images/rigging/F32075B.png",
    level: 4,
    // 
    artnr: "062-075",
    description:
    "Levereras med koner, sprint och R-clips",   
    
    featured: "true",
     //gallery images
     

     // technical
     dimension:'750 x 50 x 290 mm',
     vikt_1:'2,1 kg',

   // all cases
   all_cases:[ 
    {
        case_info:'',
        vikt_2:"",
        storlek:"",
    },

 ],
  
    
  // download
  downloadable_link:[
    {
        d_img:"./images/download.png",
        d_heading:"F32 Datablad",
        d_link:"./downloads/F32datablad.pdf"
    },
   
],
},
{
    id: 62100,
    category: "F32 Truss",
    producer: "Global Truss",
    product_name: "F32 100 cm Black",
    thumb: "./images/rigging/F32100B.png",
    level: 4,
    // 
    artnr: "062-100",
    description:
    "Levereras med koner, sprint och R-clips",   
    
    featured: "true",
     //gallery images
     

     // technical
     dimension:'1000 x 50 x 290 mm',
     vikt_1:'2,5 kg',

   // all cases
   all_cases:[ 
    {
        case_info:'',
        vikt_2:"",
        storlek:"",
    },

 ],
  
    
  // download
  downloadable_link:[
    {
        d_img:"./images/download.png",
        d_heading:"F32 Datablad",
        d_link:"./downloads/F32datablad.pdf"
    },
   
],
},
{
    id: 62150,
    category: "F32 Truss",
    producer: "Global Truss",
    product_name: "F32 150 cm Black",
    thumb: "./images/rigging/F32150B.png",
    level: 4,
    // 
    artnr: "062-150",
    description:
    "Levereras med koner, sprint och R-clips",   
    
    featured: "true",
     //gallery images
     

     // technical
     dimension:'1500 x 50 x 290 mm',
     vikt_1:'3,5 kg',

   // all cases
   all_cases:[ 
    {
        case_info:'',
        vikt_2:"",
        storlek:"",
    },

 ],
  
    
  // download
  downloadable_link:[
    {
        d_img:"./images/download.png",
        d_heading:"F32 Datablad",
        d_link:"./downloads/F32datablad.pdf"
    },
   
],
},
{
    id: 62200,
    category: "F32 Truss",
    producer: "Global Truss",
    product_name: "F32 200 cm Black",
    thumb: "./images/rigging/F32200B.png",
    level: 4,
    // 
    artnr: "062-200",
    description:
    "Levereras med koner, sprint och R-clips",   
    
    featured: "true",
     //gallery images
     

     // technical
     dimension:'2000 x 50 x 290 mm',
     vikt_1:'4,6 kg',

   // all cases
   all_cases:[ 
    {
        case_info:'',
        vikt_2:"",
        storlek:"",
    },

 ],
  
    
  // download
  downloadable_link:[
    {
        d_img:"./images/download.png",
        d_heading:"F32 Datablad",
        d_link:"./downloads/F32datablad.pdf"
    },
   
],
},
{
    id: 62300,
    category: "F32 Truss",
    producer: "Global Truss",
    product_name: "F32 300 cm Black",
    thumb: "./images/rigging/F32300b.png",
    level: 4,
    // 
    artnr: "062-300",
    description:
    "Levereras med koner, sprint och R-clips",   
    
    featured: "true",
     //gallery images
     

     // technical
     dimension:'3000 x 50 x 290 mm',
     vikt_1:'6,7 kg',

   // all cases
   all_cases:[ 
    {
        case_info:'',
        vikt_2:"",
        storlek:"",
    },

 ],
  
    
  // download
  downloadable_link:[
    {
        d_img:"./images/download.png",
        d_heading:"F32 Datablad",
        d_link:"./downloads/F32datablad.pdf"
    },
   
],
},
{
    id: 62601,
    category: "F32 Truss",
    producer: "Global Truss",
    product_name: "F32 Dogbone Cornerbox",
    thumb: "./images/rigging/F32dogbone.png",
    level: 4,
    // 
    artnr: "062-601",
    description:
    "Levereras med koner, sprint och R-clips",   
    
    featured: "true",
     //gallery images
     

     // technical
     dimension:'50 x 50 x 290 mm',
     vikt_1:'1,2 kg',

   // all cases
   all_cases:[ 
    {
        case_info:'',
        vikt_2:"",
        storlek:"",
    },

 ],
  
    
  // download
  downloadable_link:[
    {
        d_img:"./images/download.png",
        d_heading:"F32 Datablad",
        d_link:"./downloads/F32datablad.pdf"
    },
   
],
},
{
    id: 62602,
    category: "F32 Truss",
    producer: "Global Truss",
    product_name: "F32 > F34 Adapter",
    thumb: "./images/rigging/F32adapter.png",
    level: 4,
    // 
    artnr: "062-602",
    description:
    "Levereras med koner, sprint och R-clips",   
    
    featured: "true",
     //gallery images
     

     // technical
     dimension:'290 x 50 x 290 mm',
     vikt_1:'2 kg',

   // all cases
   all_cases:[ 
    {
        case_info:'',
        vikt_2:"",
        storlek:"",
    },

 ],
  
    
  // download
  downloadable_link:[
    {
        d_img:"./images/download.png",
        d_heading:"F32 Datablad",
        d_link:"./downloads/F32datablad.pdf"
    },
   
],
},
{
    id: 62635,
    category: "F32 Truss",
    producer: "Global Truss",
    product_name: "F32 135 graders hörn",
    thumb: "./images/rigging/F32135deg.png",
    level: 4,
    // 
    artnr: "062-635",
    description:
    "Levereras med koner, sprint och R-clips",   
    
    featured: "true",
     //gallery images
     

     // technical
     dimension:'900 x 50 x 290 mm',
     vikt_1:'2,8 kg',

   // all cases
   all_cases:[ 
    {
        case_info:'',
        vikt_2:"",
        storlek:"",
    },

 ],
  
    
  // download
  downloadable_link:[
    {
        d_img:"./images/download.png",
        d_heading:"F32 Datablad",
        d_link:"./downloads/F32datablad.pdf"
    },
   
],
},


//F34
{
    id: 64021,
    category: "F34 Truss",
    producer: "Global Truss",
    product_name: "F34 21 cm Black",
    thumb: "./images/rigging/F34021B.png",
    level: 4,
    // 
    artnr: "064-021",
    description:
    "Levereras med koner, sprint och R-clips",   
    
    featured: "true",
     //gallery images
    

     // technical
     dimension:'210 x 290 x 290 mm',
     vikt_1:'2,5 kg',

   // all cases
   all_cases:[ 
    {
        case_info:'',
        vikt_2:"",
        storlek:"",
    },

 ],

  // download
  downloadable_link:[
    {
        d_img:"./images/download.png",
        d_heading:"Global Truss F34 Datablad",
        d_link:"./downloads/datablad_F34.pdf"
    },
   
],
},
{
    id: 64025,
    category: "F34 Truss",
    producer: "Global Truss",
    product_name: "F34 25 cm Black",
    thumb: "./images/rigging/F34025B.png",
    level: 4,
    // 
    artnr: "064-025",
    description:
    "Levereras med koner, sprint och R-clips",   
    
    featured: "true",
     //gallery images
    

     // technical
     dimension:'250 x 290 x 290 mm',
     vikt_1:'2,9 kg',

   // all cases
   all_cases:[ 
    {
        case_info:'',
        vikt_2:"",
        storlek:"",
    },

 ],

  // download
  downloadable_link:[
    {
        d_img:"./images/download.png",
        d_heading:"Global Truss F34 Datablad",
        d_link:"./downloads/datablad_F34.pdf"
    },
   
],
},
{
    id: 64050,
    category: "F34 Truss",
    producer: "Global Truss",
    product_name: "F34 50 cm Black",
    thumb: "./images/rigging/F34050.png",
    level: 4,
    // 
    artnr: "064-050",
    description:
    "Levereras med koner, sprint och R-clips",   
    
    featured: "true",
     //gallery images
    

     // technical
     dimension:'500 x 290 x 290 mm',
     vikt_1:'4,9 kg',

   // all cases
   all_cases:[ 
    {
        case_info:'',
        vikt_2:"",
        storlek:"",
    },

 ],

  // download
  downloadable_link:[
    {
        d_img:"./images/download.png",
        d_heading:"Global Truss F34 Datablad",
        d_link:"./downloads/datablad_F34.pdf"
    },
   
],
},
{
    id: 64075,
    category: "F34 Truss",
    producer: "Global Truss",
    product_name: "F34 75 cm Black",
    thumb: "./images/rigging/F34050.png",
    level: 4,
    // 
    artnr: "064-075",
    description:
    "Levereras med koner, sprint och R-clips",   
    
    featured: "true",
     //gallery images
    

     // technical
     dimension:'750 x 290 x 290 mm',
     vikt_1:'7 kg',

   // all cases
   all_cases:[ 
    {
        case_info:'',
        vikt_2:"",
        storlek:"",
    },

 ],

  // download
  downloadable_link:[
    {
        d_img:"./images/download.png",
        d_heading:"Global Truss F34 Datablad",
        d_link:"./downloads/datablad_F34.pdf"
    },
   
],
},
{
    id: 64100,
    category: "F34 Truss",
    producer: "Global Truss",
    product_name: "F34 100 cm Black",
    thumb: "./images/rigging/F34100B.png",
    level: 4,
    // 
    artnr: "064-100",
    description:
    "Levereras med koner, sprint och R-clips",   
    
    featured: "true",
     //gallery images
    

     // technical
     dimension:'1000 x 290 x 290 mm',
     vikt_1:'7,5 kg',

   // all cases
   all_cases:[ 
    {
        case_info:'Hjulplatta för truss',
        vikt_2:"5 kg",
        storlek:"",
    },

 ],

  // download
  downloadable_link:[
    {
        d_img:"./images/download.png",
        d_heading:"Global Truss F34 Datablad",
        d_link:"./downloads/datablad_F34.pdf"
    },
   
],
},
{
    id: 64150,
    category: "F34 Truss",
    producer: "Global Truss",
    product_name: "F34 150 cm Black",
    thumb: "./images/rigging/F34150B.png",
    level: 4,
    // 
    artnr: "064-150",
    description:
    "Levereras med koner, sprint och R-clips",   
    
    featured: "true",
     //gallery images
    

     // technical
     dimension:'1500 x 290 x 290 mm',
     vikt_1:'9,7 kg',

   // all cases
   all_cases:[ 
    {
        case_info:'Hjulplatta för truss',
        vikt_2:"5 kg",
        storlek:"",
    },

 ],

  // download
  downloadable_link:[
    {
        d_img:"./images/download.png",
        d_heading:"Global Truss F34 Datablad",
        d_link:"./downloads/datablad_F34.pdf"
    },
   
],
},
{
    id: 64200,
    category: "F34 Truss",
    producer: "Global Truss",
    product_name: "F34 200 cm Black",
    thumb: "./images/rigging/F34200B.png",
    level: 4,
    // 
    artnr: "064-200",
    description:
    "Levereras med koner, sprint och R-clips",   
    
    featured: "true",
     //gallery images
    

     // technical
     dimension:'2000 x 290 x 290 mm',
     vikt_1:'12 kg',

   // all cases
   all_cases:[ 
    {
        case_info:'Hjulplatta för truss',
        vikt_2:"5 kg",
        storlek:"",
    },

 ],

  // download
  downloadable_link:[
    {
        d_img:"./images/download.png",
        d_heading:"Global Truss F34 Datablad",
        d_link:"./downloads/datablad_F34.pdf"
    },
   
],
},
{
    id: 64300,
    category: "F34 Truss",
    producer: "Global Truss",
    product_name: "F34 300 cm Black",
    thumb: "./images/rigging/F34300B.png",
    level: 4,
    // 
    artnr: "064-300",
    description:
    "Levereras med koner, sprint och R-clips",   
    
    featured: "true",
     //gallery images
    

     // technical
     dimension:'3000 x 290 x 290 mm',
     vikt_1:'16 kg',

   // all cases
   all_cases:[ 
    {
        case_info:'Hjulplatta för truss',
        vikt_2:"5 kg",
        storlek:"",
    },

 ],

  // download
  downloadable_link:[
    {
        d_img:"./images/download.png",
        d_heading:"Global Truss F34 Datablad",
        d_link:"./downloads/datablad_F34.pdf"
    },
   
],
},
{
    id: 64601,
    category: "F34 Truss",
    producer: "Global Truss",
    product_name: "F34 Cornerbox",
    thumb: "./images/rigging/F34box.png",
    level: 4,
    // 
    artnr: "064-601",
        
    description:
    "Cornerbox byggs enkelt till det hörn du önskar. Vi har en rad olika tillbehör för att kunna skapa det hörn du behöver, från ett 90 graders hörn till ett 6-vägs hörn",   
    
    featured: "true",
     //gallery images
    

     // technical
     dimension:'290 x 290 x 290 mm',
     vikt_1:'5,7 kg',

   // all cases
   all_cases:[ 
    {
        case_info:'',
        vikt_2:"",
        storlek:"",
    },

 ],

  // download
  downloadable_link:[
    {
        d_img:"./images/download.png",
        d_heading:"Global Truss F34 Datablad",
        d_link:"./downloads/datablad_F34.pdf"
    },
   
],
},
{
    id: 64645,
    category: "F34 Truss",
    producer: "Global Truss",
    product_name: "F34 135 graders hörn",
    thumb: "./images/rigging/F34135deg.png",
    level: 4,
    // 
    artnr: "064-645",
    description:
    "Levereras med koner, sprint och R-clips.",   
    
    featured: "true",
     //gallery images
    

     // technical
     dimension:'900 x 290 x 290 mm',
     vikt_1:'6 kg',

   // all cases
   all_cases:[ 
    {
        case_info:'',
        vikt_2:"",
        storlek:"",
    },

 ],

  // download
  downloadable_link:[
    {
        d_img:"./images/download.png",
        d_heading:"Global Truss F34 Datablad",
        d_link:"./downloads/datablad_F34.pdf"
    },
   
],
},
{
    id: 64701,
    category: "F34 Truss",
    producer: "Global Truss",
    product_name: "F34 Rörtopp",
    thumb: "./images/rigging/F34toptube.png",
    level: 4,
    // 
    artnr: "064-701",
    all_lists:[
        {
            list_data:"Levereras med koner, sprint och R-clips.",
        },
        {
            list_data:"Använs för att fästa t.ex lampa ovanpå Trussen",
        },
    ],

    
    featured: "true",
     //gallery images
    

     // technical
     dimension:'500 x 290 x 290 mm',
     vikt_1:'2,7 kg',

   // all cases
   all_cases:[ 
    {
        case_info:'',
        vikt_2:"",
        storlek:"",
    },

 ],

  // download
  downloadable_link:[
    {
        d_img:"./images/download.png",
        d_heading:"Global Truss F34 Datablad",
        d_link:"./downloads/datablad_F34.pdf"
    },
   
],
},

//Ground Support
{
    id: 65001,
    category: "Ground Support",
    producer: "Global Truss",
    product_name: "Komplett Ground Support torn",
    thumb: "./images/rigging/F34towerv2.png",
    level: 4,
    // 
    artnr: "065-001",
    description:
    "Komplett system",   
    
    featured: "true",
     //gallery images
     gallery_img:[
        {
            img:"./images/rigging/F34towerv2.png",
        },
        {
            img:"./images/rigging/F34towertop.png",
        },
        {
            img:"./images/rigging/F34sleeve.png",
        },
        
       
    ] ,

     // technical
     dimension:'500 x 50 x 290 mm',
     vikt_1:'1 kg',

   // all cases
   all_cases:[ 
    {
        case_info:'För 8 st Pro SLim Par',
        vikt_2:"64 kg",
        storlek:"900 x 600 x 600 mm",
    },

 ],
  
    // icons
    icons:[
        {
            icon:"./images/icons/powercon.png",
            hovertext:'PowerCon',
        },
        {
            icon:"./images/icons/led.png",
            hovertext:'LED lampa',
        },
        {
            icon:"./images/icons/wwcw.png",
            hovertext:'Varm/Kall vit',
        },
        
        {
            icon:"./images/icons/xlr3polv4.png",
            hovertext:'XLR 3-pol',
        },
      
      
    ] ,

  // download
  downloadable_link:[
    {
        d_img:"./images/download.png",
        d_heading:"Futurelight Pro Slim Par 36 manual",
        d_link:"./downloads/slimpar36.pdf"
    },
   
],
},
{
    id: 65101,
    category: "Ground Support",
    producer: "Global Truss",
    product_name: "F34 Tower Top",
    thumb: "./images/rigging/F34towertop.png",
    level: 4,
    // 
    artnr: "065-101",
    description:
    "Komplett system",   
    
    featured: "true",
     //gallery images
     gallery_img:[
        {
            img:"./images/ljus/slimpar36.png",
        },
        {
            img:"./images/ljus/slimpar36_back.png",
        },
        
       
    ] ,

     // technical
     dimension:'500 x 50 x 290 mm',
     vikt_1:'1 kg',

   // all cases
   all_cases:[ 
    {
        case_info:'För 8 st Pro SLim Par',
        vikt_2:"64 kg",
        storlek:"900 x 600 x 600 mm",
    },

 ],
  
    // icons
    icons:[
        {
            icon:"./images/icons/powercon.png",
            hovertext:'PowerCon',
        },
        {
            icon:"./images/icons/led.png",
            hovertext:'LED lampa',
        },
        {
            icon:"./images/icons/wwcw.png",
            hovertext:'Varm/Kall vit',
        },
        
        {
            icon:"./images/icons/xlr3polv4.png",
            hovertext:'XLR 3-pol',
        },
      
      
    ] ,

  // download
  downloadable_link:[
    {
        d_img:"./images/download.png",
        d_heading:"Futurelight Pro Slim Par 36 manual",
        d_link:"./downloads/slimpar36.pdf"
    },
   
],
},
{
    id: 65102,
    category: "Ground Support",
    producer: "Global Truss",
    product_name: "F34 Sleeve block",
    thumb: "./images/rigging/F34sleeve.png",
    level: 4,
    // 
    artnr: "065-102",
    description:
    "Komplett system",   
    
    featured: "true",
     //gallery images
     gallery_img:[
        {
            img:"./images/ljus/slimpar36.png",
        },
        {
            img:"./images/ljus/slimpar36_back.png",
        },
        
       
    ] ,

     // technical
     dimension:'500 x 50 x 290 mm',
     vikt_1:'1 kg',

   // all cases
   all_cases:[ 
    {
        case_info:'För 8 st Pro SLim Par',
        vikt_2:"64 kg",
        storlek:"900 x 600 x 600 mm",
    },

 ],
  
    // icons
    icons:[
        {
            icon:"./images/icons/powercon.png",
            hovertext:'PowerCon',
        },
        {
            icon:"./images/icons/led.png",
            hovertext:'LED lampa',
        },
        {
            icon:"./images/icons/wwcw.png",
            hovertext:'Varm/Kall vit',
        },
        
        {
            icon:"./images/icons/xlr3polv4.png",
            hovertext:'XLR 3-pol',
        },
      
      
    ] ,

  // download
  downloadable_link:[
    {
        d_img:"./images/download.png",
        d_heading:"Futurelight Pro Slim Par 36 manual",
        d_link:"./downloads/slimpar36.pdf"
    },
   
],
},
{
    id: 65103,
    category: "Ground Support",
    producer: "Global Truss",
    product_name: "F34 Ground Support Base",
    thumb: "./images/rigging/F34base.png",
    level: 4,
    // 
    artnr: "065-103",
    description:
    "Komplett system",   
    
    featured: "true",
     //gallery images
     gallery_img:[
        {
            img:"./images/ljus/slimpar36.png",
        },
        {
            img:"./images/ljus/slimpar36_back.png",
        },
        
       
    ] ,

     // technical
     dimension:'500 x 50 x 290 mm',
     vikt_1:'1 kg',

   // all cases
   all_cases:[ 
    {
        case_info:'För 8 st Pro SLim Par',
        vikt_2:"64 kg",
        storlek:"900 x 600 x 600 mm",
    },

 ],
  
    // icons
    icons:[
        {
            icon:"./images/icons/powercon.png",
            hovertext:'PowerCon',
        },
        {
            icon:"./images/icons/led.png",
            hovertext:'LED lampa',
        },
        {
            icon:"./images/icons/wwcw.png",
            hovertext:'Varm/Kall vit',
        },
        
        {
            icon:"./images/icons/xlr3polv4.png",
            hovertext:'XLR 3-pol',
        },
      
      
    ] ,

  // download
  downloadable_link:[
    {
        d_img:"./images/download.png",
        d_heading:"Futurelight Pro Slim Par 36 manual",
        d_link:"./downloads/slimpar36.pdf"
    },
   
],
},

]

export default product_img_menu;