import React from 'react';
// import './LogoSlider.css';

const images = [
  { src: './images/brand1.png' },
  { src: './images/brand2.png' },
  { src: './images/brand3.png' },
  { src: './images/brand4.png' },
  { src: './images/brand5.png' },
  { src: './images/brand6.png' },
  { src: './images/brand7.png' },
  { src: './images/brand8.png' },
  { src: './images/brand9.png' },
  { src: './images/brand10.png' },
  { src: './images/brand11.png' },
  { src: './images/brand12.png' },
  { src: './images/brand13.png' },
  { src: './images/brand14.png' },
  { src: './images/brand15.png' },
  { src: './images/brand16.png' },
  { src: './images/brand17.png' },
  { src: './images/brand18.png' },
];

const LogoSlider = () => {
  return (
    <div className="logo-slider-container">
      <div className="brand_heading_outer">
        <div className="brand_shape">
          <img src="./images/leftbrand.png" alt="" />
        </div>
        <div className="brand_heading">VI ANVÄNDER UTRUSTNING FRÅN</div>
        <div className="brand_shape">
          <img src="./images/rightbrand.png" alt="" />
        </div>
      </div>
      <div className="logo-slider-wrapper">
        {/* Left Blur Effect */}
        <div className="blur-effect left"></div>

        <div className="logo-slider">
          {/* Render images twice to create a seamless loop */}
          {images.map((image, index) => (
            <img key={index} src={image.src} alt={`brand-${index}`} />
          ))}
          {images.map((image, index) => (
            <img key={index + images.length} src={image.src} alt={`brand-${index}`} />
          ))}
        </div>

        {/* Right Blur Effect */}
        <div className="blur-effect right"></div>
      </div>
    </div>
  );
};

export default LogoSlider;
